/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TjekBilUserCar,
    TjekBilUserCarFromJSON,
    TjekBilUserCarFromJSONTyped,
    TjekBilUserCarToJSON,
} from './TjekBilUserCar';
import {
    TjekBilYearsWithoutIncident,
    TjekBilYearsWithoutIncidentFromJSON,
    TjekBilYearsWithoutIncidentFromJSONTyped,
    TjekBilYearsWithoutIncidentToJSON,
} from './TjekBilYearsWithoutIncident';

/**
 * 
 * @export
 * @interface TjekBilUser
 */
export interface TjekBilUser {
    /**
     * 
     * @type {number}
     * @memberof TjekBilUser
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TjekBilUser
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof TjekBilUser
     */
    zipCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilUser
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TjekBilUser
     */
    emailBounced?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TjekBilUser
     */
    emailBounceReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilUser
     */
    emailVerificationToken?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TjekBilUser
     */
    emailVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TjekBilUser
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilUser
     */
    streetName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilUser
     */
    streetNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilUser
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilUser
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TjekBilUser
     */
    yearOfBirth?: number | null;
    /**
     * 
     * @type {TjekBilYearsWithoutIncident}
     * @memberof TjekBilUser
     */
    yearsWithoutIncident?: TjekBilYearsWithoutIncident;
    /**
     * 
     * @type {number}
     * @memberof TjekBilUser
     */
    userLivesIn?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TjekBilUser
     */
    drivingPerYear?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof TjekBilUser
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TjekBilUser
     */
    lastEditedDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof TjekBilUser
     */
    syncedWithMarketing?: boolean;
    /**
     * 
     * @type {Array<TjekBilUserCar>}
     * @memberof TjekBilUser
     */
    userCars?: Array<TjekBilUserCar> | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilUser
     */
    roles?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TjekBilUser
     */
    termsAccepted?: boolean;
}

export function TjekBilUserFromJSON(json: any): TjekBilUser {
    return TjekBilUserFromJSONTyped(json, false);
}

export function TjekBilUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): TjekBilUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'emailBounced': !exists(json, 'emailBounced') ? undefined : json['emailBounced'],
        'emailBounceReason': !exists(json, 'emailBounceReason') ? undefined : json['emailBounceReason'],
        'emailVerificationToken': !exists(json, 'emailVerificationToken') ? undefined : json['emailVerificationToken'],
        'emailVerified': !exists(json, 'emailVerified') ? undefined : json['emailVerified'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'yearOfBirth': !exists(json, 'yearOfBirth') ? undefined : json['yearOfBirth'],
        'yearsWithoutIncident': !exists(json, 'yearsWithoutIncident') ? undefined : TjekBilYearsWithoutIncidentFromJSON(json['yearsWithoutIncident']),
        'userLivesIn': !exists(json, 'userLivesIn') ? undefined : json['userLivesIn'],
        'drivingPerYear': !exists(json, 'drivingPerYear') ? undefined : json['drivingPerYear'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'lastEditedDate': !exists(json, 'lastEditedDate') ? undefined : (json['lastEditedDate'] === null ? null : new Date(json['lastEditedDate'])),
        'syncedWithMarketing': !exists(json, 'syncedWithMarketing') ? undefined : json['syncedWithMarketing'],
        'userCars': !exists(json, 'userCars') ? undefined : (json['userCars'] === null ? null : (json['userCars'] as Array<any>).map(TjekBilUserCarFromJSON)),
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'termsAccepted': !exists(json, 'termsAccepted') ? undefined : json['termsAccepted'],
    };
}

export function TjekBilUserToJSON(value?: TjekBilUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'zipCode': value.zipCode,
        'name': value.name,
        'emailBounced': value.emailBounced,
        'emailBounceReason': value.emailBounceReason,
        'emailVerificationToken': value.emailVerificationToken,
        'emailVerified': value.emailVerified,
        'password': value.password,
        'streetName': value.streetName,
        'streetNumber': value.streetNumber,
        'city': value.city,
        'phoneNumber': value.phoneNumber,
        'yearOfBirth': value.yearOfBirth,
        'yearsWithoutIncident': TjekBilYearsWithoutIncidentToJSON(value.yearsWithoutIncident),
        'userLivesIn': value.userLivesIn,
        'drivingPerYear': value.drivingPerYear,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'lastEditedDate': value.lastEditedDate === undefined ? undefined : (value.lastEditedDate === null ? null : value.lastEditedDate.toISOString()),
        'syncedWithMarketing': value.syncedWithMarketing,
        'userCars': value.userCars === undefined ? undefined : (value.userCars === null ? null : (value.userCars as Array<any>).map(TjekBilUserCarToJSON)),
        'roles': value.roles,
        'termsAccepted': value.termsAccepted,
    };
}

