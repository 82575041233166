/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MotorregisterTekniskData
 */
export interface MotorregisterTekniskData {
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    totalVaegt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    egenVaegt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    koereklarVaegtMinimum?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    tekniskTotalVaegt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    vogntogVaegt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    paahaengVognTotalVaegt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    akselAntal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    siddepladserMinimum?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterTekniskData
     */
    tilkoblingsmulighed?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    tilkoblingsvaegtUdenBremser?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    tilkoblingsvaegtMedBremser?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterTekniskData
     */
    karrosseriTypeNavn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    motorSlagVolumen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    motorStoersteEffekt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    motorKmPerLiter?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    motorElektriskForbrug?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    motorElektriskForbrugMaalt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    motorBraendstofForbrugMaalt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    batteriKapacitet?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    elektriskRaekkevidde?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterTekniskData
     */
    maalenorm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterTekniskData
     */
    drivkraftTypeNavn?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterTekniskData
     */
    pluginHybrid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterTekniskData
     */
    motorMaerkning?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    maksimumHastighed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    motorCylinderAntal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    antalDoere?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterTekniskData
     */
    faelgDaek?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    sporviddenForrest?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    sporviddenBagest?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterTekniskData
     */
    stelNummerAnbringelse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    passagerAntal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterTekniskData
     */
    traekkendeAksler?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    akselAfstand?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    stoersteAkselTryk?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    miljoeOplysningCO2Udslip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    miljoeOplysningEmissionCO?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    miljoeOplysningEmissionHCPlusNOX?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    miljoeOplysningEmissionNOX?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterTekniskData
     */
    miljoeOplysningPartikelFilter?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    miljoeOplysningRoegtaethed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    miljoeOplysningRoegtaethedOmdrejningstal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    miljoeOplysningPartikler?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    motorKoerselStoej?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    motorStandStoej?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterTekniskData
     */
    motorStandStoejOmdrejningstal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterTekniskData
     */
    normTypeNavn?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterTekniskData
     */
    miljoeEftermonteretPartikelFilter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterTekniskData
     */
    readonly effektDisplay?: string | null;
}

export function MotorregisterTekniskDataFromJSON(json: any): MotorregisterTekniskData {
    return MotorregisterTekniskDataFromJSONTyped(json, false);
}

export function MotorregisterTekniskDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MotorregisterTekniskData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalVaegt': !exists(json, 'totalVaegt') ? undefined : json['totalVaegt'],
        'egenVaegt': !exists(json, 'egenVaegt') ? undefined : json['egenVaegt'],
        'koereklarVaegtMinimum': !exists(json, 'koereklarVaegtMinimum') ? undefined : json['koereklarVaegtMinimum'],
        'tekniskTotalVaegt': !exists(json, 'tekniskTotalVaegt') ? undefined : json['tekniskTotalVaegt'],
        'vogntogVaegt': !exists(json, 'vogntogVaegt') ? undefined : json['vogntogVaegt'],
        'paahaengVognTotalVaegt': !exists(json, 'paahaengVognTotalVaegt') ? undefined : json['paahaengVognTotalVaegt'],
        'akselAntal': !exists(json, 'akselAntal') ? undefined : json['akselAntal'],
        'siddepladserMinimum': !exists(json, 'siddepladserMinimum') ? undefined : json['siddepladserMinimum'],
        'tilkoblingsmulighed': !exists(json, 'tilkoblingsmulighed') ? undefined : json['tilkoblingsmulighed'],
        'tilkoblingsvaegtUdenBremser': !exists(json, 'tilkoblingsvaegtUdenBremser') ? undefined : json['tilkoblingsvaegtUdenBremser'],
        'tilkoblingsvaegtMedBremser': !exists(json, 'tilkoblingsvaegtMedBremser') ? undefined : json['tilkoblingsvaegtMedBremser'],
        'karrosseriTypeNavn': !exists(json, 'karrosseriTypeNavn') ? undefined : json['karrosseriTypeNavn'],
        'motorSlagVolumen': !exists(json, 'motorSlagVolumen') ? undefined : json['motorSlagVolumen'],
        'motorStoersteEffekt': !exists(json, 'motorStoersteEffekt') ? undefined : json['motorStoersteEffekt'],
        'motorKmPerLiter': !exists(json, 'motorKmPerLiter') ? undefined : json['motorKmPerLiter'],
        'motorElektriskForbrug': !exists(json, 'motorElektriskForbrug') ? undefined : json['motorElektriskForbrug'],
        'motorElektriskForbrugMaalt': !exists(json, 'motorElektriskForbrugMaalt') ? undefined : json['motorElektriskForbrugMaalt'],
        'motorBraendstofForbrugMaalt': !exists(json, 'motorBraendstofForbrugMaalt') ? undefined : json['motorBraendstofForbrugMaalt'],
        'batteriKapacitet': !exists(json, 'batteriKapacitet') ? undefined : json['batteriKapacitet'],
        'elektriskRaekkevidde': !exists(json, 'elektriskRaekkevidde') ? undefined : json['elektriskRaekkevidde'],
        'maalenorm': !exists(json, 'maalenorm') ? undefined : json['maalenorm'],
        'drivkraftTypeNavn': !exists(json, 'drivkraftTypeNavn') ? undefined : json['drivkraftTypeNavn'],
        'pluginHybrid': !exists(json, 'pluginHybrid') ? undefined : json['pluginHybrid'],
        'motorMaerkning': !exists(json, 'motorMaerkning') ? undefined : json['motorMaerkning'],
        'maksimumHastighed': !exists(json, 'maksimumHastighed') ? undefined : json['maksimumHastighed'],
        'motorCylinderAntal': !exists(json, 'motorCylinderAntal') ? undefined : json['motorCylinderAntal'],
        'antalDoere': !exists(json, 'antalDoere') ? undefined : json['antalDoere'],
        'faelgDaek': !exists(json, 'faelgDaek') ? undefined : json['faelgDaek'],
        'sporviddenForrest': !exists(json, 'sporviddenForrest') ? undefined : json['sporviddenForrest'],
        'sporviddenBagest': !exists(json, 'sporviddenBagest') ? undefined : json['sporviddenBagest'],
        'stelNummerAnbringelse': !exists(json, 'stelNummerAnbringelse') ? undefined : json['stelNummerAnbringelse'],
        'passagerAntal': !exists(json, 'passagerAntal') ? undefined : json['passagerAntal'],
        'traekkendeAksler': !exists(json, 'traekkendeAksler') ? undefined : json['traekkendeAksler'],
        'akselAfstand': !exists(json, 'akselAfstand') ? undefined : json['akselAfstand'],
        'stoersteAkselTryk': !exists(json, 'stoersteAkselTryk') ? undefined : json['stoersteAkselTryk'],
        'miljoeOplysningCO2Udslip': !exists(json, 'miljoeOplysningCO2Udslip') ? undefined : json['miljoeOplysningCO2Udslip'],
        'miljoeOplysningEmissionCO': !exists(json, 'miljoeOplysningEmissionCO') ? undefined : json['miljoeOplysningEmissionCO'],
        'miljoeOplysningEmissionHCPlusNOX': !exists(json, 'miljoeOplysningEmissionHCPlusNOX') ? undefined : json['miljoeOplysningEmissionHCPlusNOX'],
        'miljoeOplysningEmissionNOX': !exists(json, 'miljoeOplysningEmissionNOX') ? undefined : json['miljoeOplysningEmissionNOX'],
        'miljoeOplysningPartikelFilter': !exists(json, 'miljoeOplysningPartikelFilter') ? undefined : json['miljoeOplysningPartikelFilter'],
        'miljoeOplysningRoegtaethed': !exists(json, 'miljoeOplysningRoegtaethed') ? undefined : json['miljoeOplysningRoegtaethed'],
        'miljoeOplysningRoegtaethedOmdrejningstal': !exists(json, 'miljoeOplysningRoegtaethedOmdrejningstal') ? undefined : json['miljoeOplysningRoegtaethedOmdrejningstal'],
        'miljoeOplysningPartikler': !exists(json, 'miljoeOplysningPartikler') ? undefined : json['miljoeOplysningPartikler'],
        'motorKoerselStoej': !exists(json, 'motorKoerselStoej') ? undefined : json['motorKoerselStoej'],
        'motorStandStoej': !exists(json, 'motorStandStoej') ? undefined : json['motorStandStoej'],
        'motorStandStoejOmdrejningstal': !exists(json, 'motorStandStoejOmdrejningstal') ? undefined : json['motorStandStoejOmdrejningstal'],
        'normTypeNavn': !exists(json, 'normTypeNavn') ? undefined : json['normTypeNavn'],
        'miljoeEftermonteretPartikelFilter': !exists(json, 'miljoeEftermonteretPartikelFilter') ? undefined : json['miljoeEftermonteretPartikelFilter'],
        'effektDisplay': !exists(json, 'effektDisplay') ? undefined : json['effektDisplay'],
    };
}

export function MotorregisterTekniskDataToJSON(value?: MotorregisterTekniskData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalVaegt': value.totalVaegt,
        'egenVaegt': value.egenVaegt,
        'koereklarVaegtMinimum': value.koereklarVaegtMinimum,
        'tekniskTotalVaegt': value.tekniskTotalVaegt,
        'vogntogVaegt': value.vogntogVaegt,
        'paahaengVognTotalVaegt': value.paahaengVognTotalVaegt,
        'akselAntal': value.akselAntal,
        'siddepladserMinimum': value.siddepladserMinimum,
        'tilkoblingsmulighed': value.tilkoblingsmulighed,
        'tilkoblingsvaegtUdenBremser': value.tilkoblingsvaegtUdenBremser,
        'tilkoblingsvaegtMedBremser': value.tilkoblingsvaegtMedBremser,
        'karrosseriTypeNavn': value.karrosseriTypeNavn,
        'motorSlagVolumen': value.motorSlagVolumen,
        'motorStoersteEffekt': value.motorStoersteEffekt,
        'motorKmPerLiter': value.motorKmPerLiter,
        'motorElektriskForbrug': value.motorElektriskForbrug,
        'motorElektriskForbrugMaalt': value.motorElektriskForbrugMaalt,
        'motorBraendstofForbrugMaalt': value.motorBraendstofForbrugMaalt,
        'batteriKapacitet': value.batteriKapacitet,
        'elektriskRaekkevidde': value.elektriskRaekkevidde,
        'maalenorm': value.maalenorm,
        'drivkraftTypeNavn': value.drivkraftTypeNavn,
        'pluginHybrid': value.pluginHybrid,
        'motorMaerkning': value.motorMaerkning,
        'maksimumHastighed': value.maksimumHastighed,
        'motorCylinderAntal': value.motorCylinderAntal,
        'antalDoere': value.antalDoere,
        'faelgDaek': value.faelgDaek,
        'sporviddenForrest': value.sporviddenForrest,
        'sporviddenBagest': value.sporviddenBagest,
        'stelNummerAnbringelse': value.stelNummerAnbringelse,
        'passagerAntal': value.passagerAntal,
        'traekkendeAksler': value.traekkendeAksler,
        'akselAfstand': value.akselAfstand,
        'stoersteAkselTryk': value.stoersteAkselTryk,
        'miljoeOplysningCO2Udslip': value.miljoeOplysningCO2Udslip,
        'miljoeOplysningEmissionCO': value.miljoeOplysningEmissionCO,
        'miljoeOplysningEmissionHCPlusNOX': value.miljoeOplysningEmissionHCPlusNOX,
        'miljoeOplysningEmissionNOX': value.miljoeOplysningEmissionNOX,
        'miljoeOplysningPartikelFilter': value.miljoeOplysningPartikelFilter,
        'miljoeOplysningRoegtaethed': value.miljoeOplysningRoegtaethed,
        'miljoeOplysningRoegtaethedOmdrejningstal': value.miljoeOplysningRoegtaethedOmdrejningstal,
        'miljoeOplysningPartikler': value.miljoeOplysningPartikler,
        'motorKoerselStoej': value.motorKoerselStoej,
        'motorStandStoej': value.motorStandStoej,
        'motorStandStoejOmdrejningstal': value.motorStandStoejOmdrejningstal,
        'normTypeNavn': value.normTypeNavn,
        'miljoeEftermonteretPartikelFilter': value.miljoeEftermonteretPartikelFilter,
    };
}

