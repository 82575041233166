/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxDetail
 */
export interface TaxDetail {
    /**
     * 
     * @type {string}
     * @memberof TaxDetail
     */
    afgiftType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxDetail
     */
    hyppighed?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxDetail
     */
    pris?: string | null;
}

export function TaxDetailFromJSON(json: any): TaxDetail {
    return TaxDetailFromJSONTyped(json, false);
}

export function TaxDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'afgiftType': !exists(json, 'afgiftType') ? undefined : json['afgiftType'],
        'hyppighed': !exists(json, 'hyppighed') ? undefined : json['hyppighed'],
        'pris': !exists(json, 'pris') ? undefined : json['pris'],
    };
}

export function TaxDetailToJSON(value?: TaxDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'afgiftType': value.afgiftType,
        'hyppighed': value.hyppighed,
        'pris': value.pris,
    };
}

