/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MotorregisterAssessmentOffset,
    MotorregisterAssessmentOffsetFromJSON,
    MotorregisterAssessmentOffsetFromJSONTyped,
    MotorregisterAssessmentOffsetToJSON,
} from './MotorregisterAssessmentOffset';

/**
 * 
 * @export
 * @interface MotorregisterAssessment
 */
export interface MotorregisterAssessment {
    /**
     * 
     * @type {string}
     * @memberof MotorregisterAssessment
     */
    assessmentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterAssessment
     */
    calculationType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterAssessment
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterAssessment
     */
    carType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterAssessment
     */
    value?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterAssessment
     */
    registrationTax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterAssessment
     */
    taxPayed?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof MotorregisterAssessment
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterAssessment
     */
    valueWithoutTax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterAssessment
     */
    newValue?: number | null;
    /**
     * 
     * @type {Array<MotorregisterAssessmentOffset>}
     * @memberof MotorregisterAssessment
     */
    offsets?: Array<MotorregisterAssessmentOffset> | null;
}

export function MotorregisterAssessmentFromJSON(json: any): MotorregisterAssessment {
    return MotorregisterAssessmentFromJSONTyped(json, false);
}

export function MotorregisterAssessmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): MotorregisterAssessment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assessmentType': !exists(json, 'assessmentType') ? undefined : json['assessmentType'],
        'calculationType': !exists(json, 'calculationType') ? undefined : json['calculationType'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'carType': !exists(json, 'carType') ? undefined : json['carType'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'registrationTax': !exists(json, 'registrationTax') ? undefined : json['registrationTax'],
        'taxPayed': !exists(json, 'taxPayed') ? undefined : json['taxPayed'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'valueWithoutTax': !exists(json, 'valueWithoutTax') ? undefined : json['valueWithoutTax'],
        'newValue': !exists(json, 'newValue') ? undefined : json['newValue'],
        'offsets': !exists(json, 'offsets') ? undefined : (json['offsets'] === null ? null : (json['offsets'] as Array<any>).map(MotorregisterAssessmentOffsetFromJSON)),
    };
}

export function MotorregisterAssessmentToJSON(value?: MotorregisterAssessment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assessmentType': value.assessmentType,
        'calculationType': value.calculationType,
        'status': value.status,
        'carType': value.carType,
        'value': value.value,
        'registrationTax': value.registrationTax,
        'taxPayed': value.taxPayed,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'valueWithoutTax': value.valueWithoutTax,
        'newValue': value.newValue,
        'offsets': value.offsets === undefined ? undefined : (value.offsets === null ? null : (value.offsets as Array<any>).map(MotorregisterAssessmentOffsetToJSON)),
    };
}

