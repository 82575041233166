/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectionError
 */
export interface InspectionError {
    /**
     * 
     * @type {string}
     * @memberof InspectionError
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InspectionError
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InspectionError
     */
    description?: string | null;
}

export function InspectionErrorFromJSON(json: any): InspectionError {
    return InspectionErrorFromJSONTyped(json, false);
}

export function InspectionErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function InspectionErrorToJSON(value?: InspectionError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'title': value.title,
        'description': value.description,
    };
}

