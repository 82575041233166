/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InspectionStationName,
    InspectionStationNameFromJSON,
    InspectionStationNameFromJSONTyped,
    InspectionStationNameToJSON,
} from './InspectionStationName';

/**
 * 
 * @export
 * @interface InitialFilterData
 */
export interface InitialFilterData {
    /**
     * 
     * @type {Array<InspectionStationName>}
     * @memberof InitialFilterData
     */
    inspectionStations?: Array<InspectionStationName> | null;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof InitialFilterData
     */
    insuranceCompanies?: Array<Array<string>> | null;
}

export function InitialFilterDataFromJSON(json: any): InitialFilterData {
    return InitialFilterDataFromJSONTyped(json, false);
}

export function InitialFilterDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitialFilterData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspectionStations': !exists(json, 'inspectionStations') ? undefined : (json['inspectionStations'] === null ? null : (json['inspectionStations'] as Array<any>).map(InspectionStationNameFromJSON)),
        'insuranceCompanies': !exists(json, 'insuranceCompanies') ? undefined : json['insuranceCompanies'],
    };
}

export function InitialFilterDataToJSON(value?: InitialFilterData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspectionStations': value.inspectionStations === undefined ? undefined : (value.inspectionStations === null ? null : (value.inspectionStations as Array<any>).map(InspectionStationNameToJSON)),
        'insuranceCompanies': value.insuranceCompanies,
    };
}

