/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocCount,
    DocCountFromJSON,
    DocCountFromJSONTyped,
    DocCountToJSON,
} from './DocCount';

/**
 * 
 * @export
 * @interface VinValidityScore
 */
export interface VinValidityScore {
    /**
     * 
     * @type {number}
     * @memberof VinValidityScore
     */
    amountOfSimilarVIN?: number;
    /**
     * 
     * @type {Array<DocCount>}
     * @memberof VinValidityScore
     */
    amountOfSimilarMakeByVIN?: Array<DocCount> | null;
    /**
     * 
     * @type {Array<DocCount>}
     * @memberof VinValidityScore
     */
    amountOfSimilarModelByVIN?: Array<DocCount> | null;
}

export function VinValidityScoreFromJSON(json: any): VinValidityScore {
    return VinValidityScoreFromJSONTyped(json, false);
}

export function VinValidityScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): VinValidityScore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amountOfSimilarVIN': !exists(json, 'amountOfSimilarVIN') ? undefined : json['amountOfSimilarVIN'],
        'amountOfSimilarMakeByVIN': !exists(json, 'amountOfSimilarMakeByVIN') ? undefined : (json['amountOfSimilarMakeByVIN'] === null ? null : (json['amountOfSimilarMakeByVIN'] as Array<any>).map(DocCountFromJSON)),
        'amountOfSimilarModelByVIN': !exists(json, 'amountOfSimilarModelByVIN') ? undefined : (json['amountOfSimilarModelByVIN'] === null ? null : (json['amountOfSimilarModelByVIN'] as Array<any>).map(DocCountFromJSON)),
    };
}

export function VinValidityScoreToJSON(value?: VinValidityScore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amountOfSimilarVIN': value.amountOfSimilarVIN,
        'amountOfSimilarMakeByVIN': value.amountOfSimilarMakeByVIN === undefined ? undefined : (value.amountOfSimilarMakeByVIN === null ? null : (value.amountOfSimilarMakeByVIN as Array<any>).map(DocCountToJSON)),
        'amountOfSimilarModelByVIN': value.amountOfSimilarModelByVIN === undefined ? undefined : (value.amountOfSimilarModelByVIN === null ? null : (value.amountOfSimilarModelByVIN as Array<any>).map(DocCountToJSON)),
    };
}

