/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ToldsynByStateResult
 */
export interface ToldsynByStateResult {
    /**
     * 
     * @type {string}
     * @memberof ToldsynByStateResult
     */
    state?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ToldsynByStateResult
     */
    toldsynResultsImported?: number;
    /**
     * 
     * @type {number}
     * @memberof ToldsynByStateResult
     */
    toldsynResultsExported?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ToldsynByStateResult
     */
    vinsImported?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ToldsynByStateResult
     */
    vinsExported?: Array<string> | null;
}

export function ToldsynByStateResultFromJSON(json: any): ToldsynByStateResult {
    return ToldsynByStateResultFromJSONTyped(json, false);
}

export function ToldsynByStateResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ToldsynByStateResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': !exists(json, 'state') ? undefined : json['state'],
        'toldsynResultsImported': !exists(json, 'toldsynResultsImported') ? undefined : json['toldsynResultsImported'],
        'toldsynResultsExported': !exists(json, 'toldsynResultsExported') ? undefined : json['toldsynResultsExported'],
        'vinsImported': !exists(json, 'vinsImported') ? undefined : json['vinsImported'],
        'vinsExported': !exists(json, 'vinsExported') ? undefined : json['vinsExported'],
    };
}

export function ToldsynByStateResultToJSON(value?: ToldsynByStateResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'toldsynResultsImported': value.toldsynResultsImported,
        'toldsynResultsExported': value.toldsynResultsExported,
        'vinsImported': value.vinsImported,
        'vinsExported': value.vinsExported,
    };
}

