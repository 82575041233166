/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxTransaction
 */
export interface TaxTransaction {
    /**
     * 
     * @type {string}
     * @memberof TaxTransaction
     */
    regNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxTransaction
     */
    afgiftType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxTransaction
     */
    opgjort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxTransaction
     */
    daekkerFra?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxTransaction
     */
    daekkerTil?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxTransaction
     */
    pris?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxTransaction
     */
    fordret?: string | null;
}

export function TaxTransactionFromJSON(json: any): TaxTransaction {
    return TaxTransactionFromJSONTyped(json, false);
}

export function TaxTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
        'afgiftType': !exists(json, 'afgiftType') ? undefined : json['afgiftType'],
        'opgjort': !exists(json, 'opgjort') ? undefined : json['opgjort'],
        'daekkerFra': !exists(json, 'daekkerFra') ? undefined : json['daekkerFra'],
        'daekkerTil': !exists(json, 'daekkerTil') ? undefined : json['daekkerTil'],
        'pris': !exists(json, 'pris') ? undefined : json['pris'],
        'fordret': !exists(json, 'fordret') ? undefined : json['fordret'],
    };
}

export function TaxTransactionToJSON(value?: TaxTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'regNr': value.regNr,
        'afgiftType': value.afgiftType,
        'opgjort': value.opgjort,
        'daekkerFra': value.daekkerFra,
        'daekkerTil': value.daekkerTil,
        'pris': value.pris,
        'fordret': value.fordret,
    };
}

