/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MotorregisterAssessmentOffset
 */
export interface MotorregisterAssessmentOffset {
    /**
     * 
     * @type {string}
     * @memberof MotorregisterAssessmentOffset
     */
    reason?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterAssessmentOffset
     */
    value?: number;
}

export function MotorregisterAssessmentOffsetFromJSON(json: any): MotorregisterAssessmentOffset {
    return MotorregisterAssessmentOffsetFromJSONTyped(json, false);
}

export function MotorregisterAssessmentOffsetFromJSONTyped(json: any, ignoreDiscriminator: boolean): MotorregisterAssessmentOffset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function MotorregisterAssessmentOffsetToJSON(value?: MotorregisterAssessmentOffset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': value.reason,
        'value': value.value,
    };
}

