/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocCount
 */
export interface DocCount {
    /**
     * 
     * @type {string}
     * @memberof DocCount
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocCount
     */
    amount?: number | null;
}

export function DocCountFromJSON(json: any): DocCount {
    return DocCountFromJSONTyped(json, false);
}

export function DocCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function DocCountToJSON(value?: DocCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'amount': value.amount,
    };
}

