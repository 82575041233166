/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BasicVehicleData,
    BasicVehicleDataFromJSON,
    BasicVehicleDataToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface GetDmrByRegNrQueryRequest {
    regnr: string;
    amount?: number;
}

/**
 * 
 */
export class LookupApi extends runtime.BaseAPI {

    /**
     * Get Typeahead results for searching for a regnr
     */
    async getDmrByRegNrQueryRaw(requestParameters: GetDmrByRegNrQueryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<BasicVehicleData>>> {
        if (requestParameters.regnr === null || requestParameters.regnr === undefined) {
            throw new runtime.RequiredError('regnr','Required parameter requestParameters.regnr was null or undefined when calling getDmrByRegNrQuery.');
        }

        const queryParameters: any = {};

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/v1/lookup/regnrquery/{regnr}`.replace(`{${"regnr"}}`, encodeURIComponent(String(requestParameters.regnr))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BasicVehicleDataFromJSON));
    }

    /**
     * Get Typeahead results for searching for a regnr
     */
    async getDmrByRegNrQuery(requestParameters: GetDmrByRegNrQueryRequest, initOverrides?: RequestInit): Promise<Array<BasicVehicleData>> {
        const response = await this.getDmrByRegNrQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
