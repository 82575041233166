/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TekniskData,
    TekniskDataFromJSON,
    TekniskDataFromJSONTyped,
    TekniskDataToJSON,
} from './TekniskData';
import {
    TjekBilInspection,
    TjekBilInspectionFromJSON,
    TjekBilInspectionFromJSONTyped,
    TjekBilInspectionToJSON,
} from './TjekBilInspection';

/**
 * 
 * @export
 * @interface VehicleDocumentWithToldsyn
 */
export interface VehicleDocumentWithToldsyn {
    /**
     * 
     * @type {TekniskData}
     * @memberof VehicleDocumentWithToldsyn
     */
    data?: TekniskData;
    /**
     * 
     * @type {TjekBilInspection}
     * @memberof VehicleDocumentWithToldsyn
     */
    inspection?: TjekBilInspection;
    /**
     * 
     * @type {string}
     * @memberof VehicleDocumentWithToldsyn
     */
    insurance?: string | null;
}

export function VehicleDocumentWithToldsynFromJSON(json: any): VehicleDocumentWithToldsyn {
    return VehicleDocumentWithToldsynFromJSONTyped(json, false);
}

export function VehicleDocumentWithToldsynFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleDocumentWithToldsyn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : TekniskDataFromJSON(json['data']),
        'inspection': !exists(json, 'inspection') ? undefined : TjekBilInspectionFromJSON(json['inspection']),
        'insurance': !exists(json, 'insurance') ? undefined : json['insurance'],
    };
}

export function VehicleDocumentWithToldsynToJSON(value?: VehicleDocumentWithToldsyn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': TekniskDataToJSON(value.data),
        'inspection': TjekBilInspectionToJSON(value.inspection),
        'insurance': value.insurance,
    };
}

