/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Creditor
 */
export interface Creditor {
    /**
     * 
     * @type {string}
     * @memberof Creditor
     */
    navn?: string | null;
}

export function CreditorFromJSON(json: any): Creditor {
    return CreditorFromJSONTyped(json, false);
}

export function CreditorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Creditor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'navn': !exists(json, 'navn') ? undefined : json['navn'],
    };
}

export function CreditorToJSON(value?: Creditor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'navn': value.navn,
    };
}

