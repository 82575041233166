/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HesselLead
 */
export interface HesselLead {
    /**
     * 
     * @type {string}
     * @memberof HesselLead
     */
    formName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HesselLead
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HesselLead
     */
    licencePlate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HesselLead
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HesselLead
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HesselLead
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HesselLead
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HesselLead
     */
    consent?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof HesselLead
     */
    created?: Date | null;
}

export function HesselLeadFromJSON(json: any): HesselLead {
    return HesselLeadFromJSONTyped(json, false);
}

export function HesselLeadFromJSONTyped(json: any, ignoreDiscriminator: boolean): HesselLead {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formName': !exists(json, 'formName') ? undefined : json['formName'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'licencePlate': !exists(json, 'licencePlate') ? undefined : json['licencePlate'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'consent': !exists(json, 'consent') ? undefined : json['consent'],
        'created': !exists(json, 'created') ? undefined : (json['created'] === null ? null : new Date(json['created'])),
    };
}

export function HesselLeadToJSON(value?: HesselLead | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formName': value.formName,
        'url': value.url,
        'licencePlate': value.licencePlate,
        'make': value.make,
        'model': value.model,
        'email': value.email,
        'name': value.name,
        'consent': value.consent,
        'created': value.created === undefined ? undefined : (value.created === null ? null : value.created.toISOString()),
    };
}

