/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tax
 */
export interface Tax {
    /**
     * 
     * @type {string}
     * @memberof Tax
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tax
     */
    interval?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Tax
     */
    amount?: number;
}

export function TaxFromJSON(json: any): Tax {
    return TaxFromJSONTyped(json, false);
}

export function TaxFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tax {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function TaxToJSON(value?: Tax | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'interval': value.interval,
        'amount': value.amount,
    };
}

