/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TaxDetail,
    TaxDetailFromJSON,
    TaxDetailFromJSONTyped,
    TaxDetailToJSON,
} from './TaxDetail';
import {
    TaxTransaction,
    TaxTransactionFromJSON,
    TaxTransactionFromJSONTyped,
    TaxTransactionToJSON,
} from './TaxTransaction';

/**
 * 
 * @export
 * @interface TaxData
 */
export interface TaxData {
    /**
     * 
     * @type {Array<TaxTransaction>}
     * @memberof TaxData
     */
    opkraevedeAfgifter?: Array<TaxTransaction> | null;
    /**
     * 
     * @type {Array<TaxDetail>}
     * @memberof TaxData
     */
    afgiftDetaljer?: Array<TaxDetail> | null;
}

export function TaxDataFromJSON(json: any): TaxData {
    return TaxDataFromJSONTyped(json, false);
}

export function TaxDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'opkraevedeAfgifter': !exists(json, 'opkraevedeAfgifter') ? undefined : (json['opkraevedeAfgifter'] === null ? null : (json['opkraevedeAfgifter'] as Array<any>).map(TaxTransactionFromJSON)),
        'afgiftDetaljer': !exists(json, 'afgiftDetaljer') ? undefined : (json['afgiftDetaljer'] === null ? null : (json['afgiftDetaljer'] as Array<any>).map(TaxDetailFromJSON)),
    };
}

export function TaxDataToJSON(value?: TaxData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'opkraevedeAfgifter': value.opkraevedeAfgifter === undefined ? undefined : (value.opkraevedeAfgifter === null ? null : (value.opkraevedeAfgifter as Array<any>).map(TaxTransactionToJSON)),
        'afgiftDetaljer': value.afgiftDetaljer === undefined ? undefined : (value.afgiftDetaljer === null ? null : (value.afgiftDetaljer as Array<any>).map(TaxDetailToJSON)),
    };
}

