
import { LookupApi } from '../lib-api-tjekbil/apis/LookupApi';
import {
  Configuration as TjekbilConfiguration,
} from '../lib-api-tjekbil/runtime';

const basePathTjekbil = process.env.NEXT_PUBLIC_TJEKBIL_API;
export const defaultApiConfiguration = new TjekbilConfiguration({
  basePath: basePathTjekbil,
});

export const useTjekbilApi = () => {
  return {
    lookupApi: new LookupApi(defaultApiConfiguration),
  }
};
