/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsuranceData
 */
export interface InsuranceData {
    /**
     * 
     * @type {string}
     * @memberof InsuranceData
     */
    selskab?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceData
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceData
     */
    oprettet?: string | null;
    /**
     * 
     * @type {Array<InsuranceData>}
     * @memberof InsuranceData
     */
    historik?: Array<InsuranceData> | null;
}

export function InsuranceDataFromJSON(json: any): InsuranceData {
    return InsuranceDataFromJSONTyped(json, false);
}

export function InsuranceDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selskab': !exists(json, 'selskab') ? undefined : json['selskab'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'oprettet': !exists(json, 'oprettet') ? undefined : json['oprettet'],
        'historik': !exists(json, 'historik') ? undefined : (json['historik'] === null ? null : (json['historik'] as Array<any>).map(InsuranceDataFromJSON)),
    };
}

export function InsuranceDataToJSON(value?: InsuranceData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selskab': value.selskab,
        'status': value.status,
        'oprettet': value.oprettet,
        'historik': value.historik === undefined ? undefined : (value.historik === null ? null : (value.historik as Array<any>).map(InsuranceDataToJSON)),
    };
}

