/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GlobalVehicleData
 */
export interface GlobalVehicleData {
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    regNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    vin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    status?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GlobalVehicleData
     */
    firstRegistrationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GlobalVehicleData
     */
    leasingValidFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GlobalVehicleData
     */
    leasingValidTo?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    vehicleType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    vehicleUsage?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    totalWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    nettoWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    axleAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    seats?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalVehicleData
     */
    connectionOption?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    connectionWeightWithoutBrakes?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    connectionWeightWithBrakes?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalVehicleData
     */
    imported?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    importedState?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GlobalVehicleData
     */
    firstRegistrationDateInCountry?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GlobalVehicleData
     */
    lastOwnerChange?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    variant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    modelYear?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    engineStrokeVolume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    motorEffectivePower?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    fuelConsumption?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    lastKnownMileage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    fuelType?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GlobalVehicleData
     */
    equipment?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    doorAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    c02Emission?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    noxEmission?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalVehicleData
     */
    particleFilter?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    idleNoise?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    euronorm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    source?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalVehicleData
     */
    firstRegistrationYear?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    localVehicleId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    lastInspectionType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GlobalVehicleData
     */
    lastInspectionDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    lastInspectionResult?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GlobalVehicleData
     */
    nextInspectionDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GlobalVehicleData
     */
    deregistrationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalVehicleData
     */
    country?: string | null;
}

export function GlobalVehicleDataFromJSON(json: any): GlobalVehicleData {
    return GlobalVehicleDataFromJSONTyped(json, false);
}

export function GlobalVehicleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalVehicleData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'firstRegistrationDate': !exists(json, 'firstRegistrationDate') ? undefined : (json['firstRegistrationDate'] === null ? null : new Date(json['firstRegistrationDate'])),
        'leasingValidFrom': !exists(json, 'leasingValidFrom') ? undefined : (json['leasingValidFrom'] === null ? null : new Date(json['leasingValidFrom'])),
        'leasingValidTo': !exists(json, 'leasingValidTo') ? undefined : (json['leasingValidTo'] === null ? null : new Date(json['leasingValidTo'])),
        'vehicleType': !exists(json, 'vehicleType') ? undefined : json['vehicleType'],
        'vehicleUsage': !exists(json, 'vehicleUsage') ? undefined : json['vehicleUsage'],
        'totalWeight': !exists(json, 'totalWeight') ? undefined : json['totalWeight'],
        'nettoWeight': !exists(json, 'nettoWeight') ? undefined : json['nettoWeight'],
        'axleAmount': !exists(json, 'axleAmount') ? undefined : json['axleAmount'],
        'seats': !exists(json, 'seats') ? undefined : json['seats'],
        'connectionOption': !exists(json, 'connectionOption') ? undefined : json['connectionOption'],
        'connectionWeightWithoutBrakes': !exists(json, 'connectionWeightWithoutBrakes') ? undefined : json['connectionWeightWithoutBrakes'],
        'connectionWeightWithBrakes': !exists(json, 'connectionWeightWithBrakes') ? undefined : json['connectionWeightWithBrakes'],
        'imported': !exists(json, 'imported') ? undefined : json['imported'],
        'importedState': !exists(json, 'importedState') ? undefined : json['importedState'],
        'firstRegistrationDateInCountry': !exists(json, 'firstRegistrationDateInCountry') ? undefined : (json['firstRegistrationDateInCountry'] === null ? null : new Date(json['firstRegistrationDateInCountry'])),
        'lastOwnerChange': !exists(json, 'lastOwnerChange') ? undefined : (json['lastOwnerChange'] === null ? null : new Date(json['lastOwnerChange'])),
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'variant': !exists(json, 'variant') ? undefined : json['variant'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'modelYear': !exists(json, 'modelYear') ? undefined : json['modelYear'],
        'engineStrokeVolume': !exists(json, 'engineStrokeVolume') ? undefined : json['engineStrokeVolume'],
        'motorEffectivePower': !exists(json, 'motorEffectivePower') ? undefined : json['motorEffectivePower'],
        'fuelConsumption': !exists(json, 'fuelConsumption') ? undefined : json['fuelConsumption'],
        'lastKnownMileage': !exists(json, 'lastKnownMileage') ? undefined : json['lastKnownMileage'],
        'fuelType': !exists(json, 'fuelType') ? undefined : json['fuelType'],
        'equipment': !exists(json, 'equipment') ? undefined : json['equipment'],
        'doorAmount': !exists(json, 'doorAmount') ? undefined : json['doorAmount'],
        'c02Emission': !exists(json, 'c02Emission') ? undefined : json['c02Emission'],
        'noxEmission': !exists(json, 'noxEmission') ? undefined : json['noxEmission'],
        'particleFilter': !exists(json, 'particleFilter') ? undefined : json['particleFilter'],
        'idleNoise': !exists(json, 'idleNoise') ? undefined : json['idleNoise'],
        'euronorm': !exists(json, 'euronorm') ? undefined : json['euronorm'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'firstRegistrationYear': !exists(json, 'firstRegistrationYear') ? undefined : json['firstRegistrationYear'],
        'localVehicleId': !exists(json, 'localVehicleId') ? undefined : json['localVehicleId'],
        'lastInspectionType': !exists(json, 'lastInspectionType') ? undefined : json['lastInspectionType'],
        'lastInspectionDate': !exists(json, 'lastInspectionDate') ? undefined : (json['lastInspectionDate'] === null ? null : new Date(json['lastInspectionDate'])),
        'lastInspectionResult': !exists(json, 'lastInspectionResult') ? undefined : json['lastInspectionResult'],
        'nextInspectionDate': !exists(json, 'nextInspectionDate') ? undefined : (json['nextInspectionDate'] === null ? null : new Date(json['nextInspectionDate'])),
        'deregistrationDate': !exists(json, 'deregistrationDate') ? undefined : (json['deregistrationDate'] === null ? null : new Date(json['deregistrationDate'])),
        'country': !exists(json, 'country') ? undefined : json['country'],
    };
}

export function GlobalVehicleDataToJSON(value?: GlobalVehicleData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'regNr': value.regNr,
        'vin': value.vin,
        'status': value.status,
        'firstRegistrationDate': value.firstRegistrationDate === undefined ? undefined : (value.firstRegistrationDate === null ? null : value.firstRegistrationDate.toISOString()),
        'leasingValidFrom': value.leasingValidFrom === undefined ? undefined : (value.leasingValidFrom === null ? null : value.leasingValidFrom.toISOString()),
        'leasingValidTo': value.leasingValidTo === undefined ? undefined : (value.leasingValidTo === null ? null : value.leasingValidTo.toISOString()),
        'vehicleType': value.vehicleType,
        'vehicleUsage': value.vehicleUsage,
        'totalWeight': value.totalWeight,
        'nettoWeight': value.nettoWeight,
        'axleAmount': value.axleAmount,
        'seats': value.seats,
        'connectionOption': value.connectionOption,
        'connectionWeightWithoutBrakes': value.connectionWeightWithoutBrakes,
        'connectionWeightWithBrakes': value.connectionWeightWithBrakes,
        'imported': value.imported,
        'importedState': value.importedState,
        'firstRegistrationDateInCountry': value.firstRegistrationDateInCountry === undefined ? undefined : (value.firstRegistrationDateInCountry === null ? null : value.firstRegistrationDateInCountry.toISOString()),
        'lastOwnerChange': value.lastOwnerChange === undefined ? undefined : (value.lastOwnerChange === null ? null : value.lastOwnerChange.toISOString()),
        'make': value.make,
        'model': value.model,
        'variant': value.variant,
        'type': value.type,
        'color': value.color,
        'modelYear': value.modelYear,
        'engineStrokeVolume': value.engineStrokeVolume,
        'motorEffectivePower': value.motorEffectivePower,
        'fuelConsumption': value.fuelConsumption,
        'lastKnownMileage': value.lastKnownMileage,
        'fuelType': value.fuelType,
        'equipment': value.equipment,
        'doorAmount': value.doorAmount,
        'c02Emission': value.c02Emission,
        'noxEmission': value.noxEmission,
        'particleFilter': value.particleFilter,
        'idleNoise': value.idleNoise,
        'euronorm': value.euronorm,
        'source': value.source,
        'firstRegistrationYear': value.firstRegistrationYear,
        'localVehicleId': value.localVehicleId,
        'lastInspectionType': value.lastInspectionType,
        'lastInspectionDate': value.lastInspectionDate === undefined ? undefined : (value.lastInspectionDate === null ? null : value.lastInspectionDate.toISOString()),
        'lastInspectionResult': value.lastInspectionResult,
        'nextInspectionDate': value.nextInspectionDate === undefined ? undefined : (value.nextInspectionDate === null ? null : value.nextInspectionDate.toISOString()),
        'deregistrationDate': value.deregistrationDate === undefined ? undefined : (value.deregistrationDate === null ? null : value.deregistrationDate.toISOString()),
        'country': value.country,
    };
}

