/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BankruptcyData,
    BankruptcyDataFromJSON,
    BankruptcyDataFromJSONTyped,
    BankruptcyDataToJSON,
} from './BankruptcyData';
import {
    LoanDocument,
    LoanDocumentFromJSON,
    LoanDocumentFromJSONTyped,
    LoanDocumentToJSON,
} from './LoanDocument';

/**
 * 
 * @export
 * @interface DebtData
 */
export interface DebtData {
    /**
     * 
     * @type {Array<LoanDocument>}
     * @memberof DebtData
     */
    laaneDokumenter?: Array<LoanDocument> | null;
    /**
     * 
     * @type {BankruptcyData}
     * @memberof DebtData
     */
    konkurs?: BankruptcyData;
}

export function DebtDataFromJSON(json: any): DebtData {
    return DebtDataFromJSONTyped(json, false);
}

export function DebtDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebtData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'laaneDokumenter': !exists(json, 'laaneDokumenter') ? undefined : (json['laaneDokumenter'] === null ? null : (json['laaneDokumenter'] as Array<any>).map(LoanDocumentFromJSON)),
        'konkurs': !exists(json, 'konkurs') ? undefined : BankruptcyDataFromJSON(json['konkurs']),
    };
}

export function DebtDataToJSON(value?: DebtData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'laaneDokumenter': value.laaneDokumenter === undefined ? undefined : (value.laaneDokumenter === null ? null : (value.laaneDokumenter as Array<any>).map(LoanDocumentToJSON)),
        'konkurs': BankruptcyDataToJSON(value.konkurs),
    };
}

