/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MotorregisterWaiverData
 */
export interface MotorregisterWaiverData {
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterWaiverData
     */
    bilenErLeaset?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MotorregisterWaiverData
     */
    leasingStart?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MotorregisterWaiverData
     */
    leasingEnd?: Date | null;
}

export function MotorregisterWaiverDataFromJSON(json: any): MotorregisterWaiverData {
    return MotorregisterWaiverDataFromJSONTyped(json, false);
}

export function MotorregisterWaiverDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MotorregisterWaiverData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bilenErLeaset': !exists(json, 'bilenErLeaset') ? undefined : json['bilenErLeaset'],
        'leasingStart': !exists(json, 'leasingStart') ? undefined : (json['leasingStart'] === null ? null : new Date(json['leasingStart'])),
        'leasingEnd': !exists(json, 'leasingEnd') ? undefined : (json['leasingEnd'] === null ? null : new Date(json['leasingEnd'])),
    };
}

export function MotorregisterWaiverDataToJSON(value?: MotorregisterWaiverData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bilenErLeaset': value.bilenErLeaset,
        'leasingStart': value.leasingStart === undefined ? undefined : (value.leasingStart === null ? null : value.leasingStart.toISOString()),
        'leasingEnd': value.leasingEnd === undefined ? undefined : (value.leasingEnd === null ? null : value.leasingEnd.toISOString()),
    };
}

