/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ToldsynByLeasingYearEndResult
 */
export interface ToldsynByLeasingYearEndResult {
    /**
     * 
     * @type {string}
     * @memberof ToldsynByLeasingYearEndResult
     */
    leasingYearEnd?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ToldsynByLeasingYearEndResult
     */
    toldsynResultsImported?: number;
    /**
     * 
     * @type {number}
     * @memberof ToldsynByLeasingYearEndResult
     */
    toldsynResultsExported?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ToldsynByLeasingYearEndResult
     */
    vinsImported?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ToldsynByLeasingYearEndResult
     */
    vinsExported?: Array<string> | null;
}

export function ToldsynByLeasingYearEndResultFromJSON(json: any): ToldsynByLeasingYearEndResult {
    return ToldsynByLeasingYearEndResultFromJSONTyped(json, false);
}

export function ToldsynByLeasingYearEndResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ToldsynByLeasingYearEndResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leasingYearEnd': !exists(json, 'leasingYearEnd') ? undefined : json['leasingYearEnd'],
        'toldsynResultsImported': !exists(json, 'toldsynResultsImported') ? undefined : json['toldsynResultsImported'],
        'toldsynResultsExported': !exists(json, 'toldsynResultsExported') ? undefined : json['toldsynResultsExported'],
        'vinsImported': !exists(json, 'vinsImported') ? undefined : json['vinsImported'],
        'vinsExported': !exists(json, 'vinsExported') ? undefined : json['vinsExported'],
    };
}

export function ToldsynByLeasingYearEndResultToJSON(value?: ToldsynByLeasingYearEndResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'leasingYearEnd': value.leasingYearEnd,
        'toldsynResultsImported': value.toldsynResultsImported,
        'toldsynResultsExported': value.toldsynResultsExported,
        'vinsImported': value.vinsImported,
        'vinsExported': value.vinsExported,
    };
}

