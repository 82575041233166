/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BankruptcyData
 */
export interface BankruptcyData {
    /**
     * 
     * @type {string}
     * @memberof BankruptcyData
     */
    afgrelseDato?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankruptcyData
     */
    tinglysningDato?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankruptcyData
     */
    tillgstekst?: string | null;
}

export function BankruptcyDataFromJSON(json: any): BankruptcyData {
    return BankruptcyDataFromJSONTyped(json, false);
}

export function BankruptcyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankruptcyData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'afgrelseDato': !exists(json, 'afgørelseDato') ? undefined : json['afgørelseDato'],
        'tinglysningDato': !exists(json, 'tinglysningDato') ? undefined : json['tinglysningDato'],
        'tillgstekst': !exists(json, 'tillægstekst') ? undefined : json['tillægstekst'],
    };
}

export function BankruptcyDataToJSON(value?: BankruptcyData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'afgørelseDato': value.afgrelseDato,
        'tinglysningDato': value.tinglysningDato,
        'tillægstekst': value.tillgstekst,
    };
}

