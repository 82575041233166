/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PoliceWantedStatus,
    PoliceWantedStatusFromJSON,
    PoliceWantedStatusFromJSONTyped,
    PoliceWantedStatusToJSON,
} from './PoliceWantedStatus';
import {
    TjekBilCarWantedHistory,
    TjekBilCarWantedHistoryFromJSON,
    TjekBilCarWantedHistoryFromJSONTyped,
    TjekBilCarWantedHistoryToJSON,
} from './TjekBilCarWantedHistory';

/**
 * 
 * @export
 * @interface PolitiData
 */
export interface PolitiData {
    /**
     * 
     * @type {PoliceWantedStatus}
     * @memberof PolitiData
     */
    policeWantedStatus?: PoliceWantedStatus;
    /**
     * 
     * @type {string}
     * @memberof PolitiData
     */
    tid?: string;
    /**
     * 
     * @type {Array<TjekBilCarWantedHistory>}
     * @memberof PolitiData
     */
    wantedHistories?: Array<TjekBilCarWantedHistory> | null;
}

export function PolitiDataFromJSON(json: any): PolitiData {
    return PolitiDataFromJSONTyped(json, false);
}

export function PolitiDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolitiData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policeWantedStatus': !exists(json, 'policeWantedStatus') ? undefined : PoliceWantedStatusFromJSON(json['policeWantedStatus']),
        'tid': !exists(json, 'tid') ? undefined : json['tid'],
        'wantedHistories': !exists(json, 'wantedHistories') ? undefined : (json['wantedHistories'] === null ? null : (json['wantedHistories'] as Array<any>).map(TjekBilCarWantedHistoryFromJSON)),
    };
}

export function PolitiDataToJSON(value?: PolitiData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policeWantedStatus': PoliceWantedStatusToJSON(value.policeWantedStatus),
        'tid': value.tid,
        'wantedHistories': value.wantedHistories === undefined ? undefined : (value.wantedHistories === null ? null : (value.wantedHistories as Array<any>).map(TjekBilCarWantedHistoryToJSON)),
    };
}

