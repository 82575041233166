/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BasicVehicleData
 */
export interface BasicVehicleData {
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    regNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    stelNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    status?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BasicVehicleData
     */
    statusDato?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof BasicVehicleData
     */
    foersteRegistreringDato?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof BasicVehicleData
     */
    leasingGyldigFra?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof BasicVehicleData
     */
    leasingGyldigTil?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    readonly leasingPeriode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    readonly foersteRegistreringDatoFormateret?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    koeretoejArtNavn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    koeretoejAnvendelseNavn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    totalVaegt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    egenVaegt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    vogntogVaegt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    koereklarVaegtMinimum?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    tekniskTotalVaegt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    paahaengVognTotalVaegt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    akselAntal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    siddepladserMinimum?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BasicVehicleData
     */
    tilkoblingsmulighed?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    tilkoblingsvaegtUdenBremser?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    tilkoblingsvaegtMedBremser?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BasicVehicleData
     */
    ncapTest?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    koeretoejstand?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    maerkeTypeNavn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    modelTypeNavn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    variantTypeNavn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    farveTypeNavn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    modelAar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    karrosseriTypeNavn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    motorSlagVolumen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    readonly motorStoerrelse?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    motorStoersteEffekt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    readonly motorHestekraefter?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    motorKmPerLiter?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    motorKilometerstand?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    motorElektriskForbrug?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    drivkraftTypeNavn?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicVehicleData
     */
    koeretoejUdstyrSamling?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    motorMaerkning?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    maksimumHastighed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    motorCylinderAntal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    antalDoere?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    faelgDaek?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    sporviddenForrest?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    sporviddenBagest?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    stelNummerAnbringelse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    passagerAntal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    traekkendeAksler?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    akselAfstand?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    stoersteAkselTryk?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    miljoeOplysningCO2Udslip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    miljoeOplysningEmissionCO?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    miljoeOplysningEmissionHCPlusNOX?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    miljoeOplysningEmissionNOX?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BasicVehicleData
     */
    miljoeOplysningPartikelFilter?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    miljoeOplysningRoegtaethed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    miljoeOplysningRoegtaethedOmdrejningstal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    miljoeOplysningPartikler?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    motorKoerselStoej?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    motorStandStoej?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    motorStandStoejOmdrejningstal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    normTypeNavn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    adressePostNummer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    adressePostNummerBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BasicVehicleData
     */
    koeretoejId?: number;
    /**
     * 
     * @type {string}
     * @memberof BasicVehicleData
     */
    sekundaerStatus?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BasicVehicleData
     */
    sekundaerStatusDato?: Date | null;
}

export function BasicVehicleDataFromJSON(json: any): BasicVehicleData {
    return BasicVehicleDataFromJSONTyped(json, false);
}

export function BasicVehicleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasicVehicleData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
        'stelNr': !exists(json, 'stelNr') ? undefined : json['stelNr'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusDato': !exists(json, 'statusDato') ? undefined : (json['statusDato'] === null ? null : new Date(json['statusDato'])),
        'foersteRegistreringDato': !exists(json, 'foersteRegistreringDato') ? undefined : (json['foersteRegistreringDato'] === null ? null : new Date(json['foersteRegistreringDato'])),
        'leasingGyldigFra': !exists(json, 'leasingGyldigFra') ? undefined : (json['leasingGyldigFra'] === null ? null : new Date(json['leasingGyldigFra'])),
        'leasingGyldigTil': !exists(json, 'leasingGyldigTil') ? undefined : (json['leasingGyldigTil'] === null ? null : new Date(json['leasingGyldigTil'])),
        'leasingPeriode': !exists(json, 'leasingPeriode') ? undefined : json['leasingPeriode'],
        'foersteRegistreringDatoFormateret': !exists(json, 'foersteRegistreringDatoFormateret') ? undefined : json['foersteRegistreringDatoFormateret'],
        'koeretoejArtNavn': !exists(json, 'koeretoejArtNavn') ? undefined : json['koeretoejArtNavn'],
        'koeretoejAnvendelseNavn': !exists(json, 'koeretoejAnvendelseNavn') ? undefined : json['koeretoejAnvendelseNavn'],
        'totalVaegt': !exists(json, 'totalVaegt') ? undefined : json['totalVaegt'],
        'egenVaegt': !exists(json, 'egenVaegt') ? undefined : json['egenVaegt'],
        'vogntogVaegt': !exists(json, 'vogntogVaegt') ? undefined : json['vogntogVaegt'],
        'koereklarVaegtMinimum': !exists(json, 'koereklarVaegtMinimum') ? undefined : json['koereklarVaegtMinimum'],
        'tekniskTotalVaegt': !exists(json, 'tekniskTotalVaegt') ? undefined : json['tekniskTotalVaegt'],
        'paahaengVognTotalVaegt': !exists(json, 'paahaengVognTotalVaegt') ? undefined : json['paahaengVognTotalVaegt'],
        'akselAntal': !exists(json, 'akselAntal') ? undefined : json['akselAntal'],
        'siddepladserMinimum': !exists(json, 'siddepladserMinimum') ? undefined : json['siddepladserMinimum'],
        'tilkoblingsmulighed': !exists(json, 'tilkoblingsmulighed') ? undefined : json['tilkoblingsmulighed'],
        'tilkoblingsvaegtUdenBremser': !exists(json, 'tilkoblingsvaegtUdenBremser') ? undefined : json['tilkoblingsvaegtUdenBremser'],
        'tilkoblingsvaegtMedBremser': !exists(json, 'tilkoblingsvaegtMedBremser') ? undefined : json['tilkoblingsvaegtMedBremser'],
        'ncapTest': !exists(json, 'ncapTest') ? undefined : json['ncapTest'],
        'koeretoejstand': !exists(json, 'koeretoejstand') ? undefined : json['koeretoejstand'],
        'maerkeTypeNavn': !exists(json, 'maerkeTypeNavn') ? undefined : json['maerkeTypeNavn'],
        'modelTypeNavn': !exists(json, 'modelTypeNavn') ? undefined : json['modelTypeNavn'],
        'variantTypeNavn': !exists(json, 'variantTypeNavn') ? undefined : json['variantTypeNavn'],
        'farveTypeNavn': !exists(json, 'farveTypeNavn') ? undefined : json['farveTypeNavn'],
        'modelAar': !exists(json, 'modelAar') ? undefined : json['modelAar'],
        'karrosseriTypeNavn': !exists(json, 'karrosseriTypeNavn') ? undefined : json['karrosseriTypeNavn'],
        'motorSlagVolumen': !exists(json, 'motorSlagVolumen') ? undefined : json['motorSlagVolumen'],
        'motorStoerrelse': !exists(json, 'motorStoerrelse') ? undefined : json['motorStoerrelse'],
        'motorStoersteEffekt': !exists(json, 'motorStoersteEffekt') ? undefined : json['motorStoersteEffekt'],
        'motorHestekraefter': !exists(json, 'motorHestekraefter') ? undefined : json['motorHestekraefter'],
        'motorKmPerLiter': !exists(json, 'motorKmPerLiter') ? undefined : json['motorKmPerLiter'],
        'motorKilometerstand': !exists(json, 'motorKilometerstand') ? undefined : json['motorKilometerstand'],
        'motorElektriskForbrug': !exists(json, 'motorElektriskForbrug') ? undefined : json['motorElektriskForbrug'],
        'drivkraftTypeNavn': !exists(json, 'drivkraftTypeNavn') ? undefined : json['drivkraftTypeNavn'],
        'koeretoejUdstyrSamling': !exists(json, 'koeretoejUdstyrSamling') ? undefined : json['koeretoejUdstyrSamling'],
        'motorMaerkning': !exists(json, 'motorMaerkning') ? undefined : json['motorMaerkning'],
        'maksimumHastighed': !exists(json, 'maksimumHastighed') ? undefined : json['maksimumHastighed'],
        'motorCylinderAntal': !exists(json, 'motorCylinderAntal') ? undefined : json['motorCylinderAntal'],
        'antalDoere': !exists(json, 'antalDoere') ? undefined : json['antalDoere'],
        'faelgDaek': !exists(json, 'faelgDaek') ? undefined : json['faelgDaek'],
        'sporviddenForrest': !exists(json, 'sporviddenForrest') ? undefined : json['sporviddenForrest'],
        'sporviddenBagest': !exists(json, 'sporviddenBagest') ? undefined : json['sporviddenBagest'],
        'stelNummerAnbringelse': !exists(json, 'stelNummerAnbringelse') ? undefined : json['stelNummerAnbringelse'],
        'passagerAntal': !exists(json, 'passagerAntal') ? undefined : json['passagerAntal'],
        'traekkendeAksler': !exists(json, 'traekkendeAksler') ? undefined : json['traekkendeAksler'],
        'akselAfstand': !exists(json, 'akselAfstand') ? undefined : json['akselAfstand'],
        'stoersteAkselTryk': !exists(json, 'stoersteAkselTryk') ? undefined : json['stoersteAkselTryk'],
        'miljoeOplysningCO2Udslip': !exists(json, 'miljoeOplysningCO2Udslip') ? undefined : json['miljoeOplysningCO2Udslip'],
        'miljoeOplysningEmissionCO': !exists(json, 'miljoeOplysningEmissionCO') ? undefined : json['miljoeOplysningEmissionCO'],
        'miljoeOplysningEmissionHCPlusNOX': !exists(json, 'miljoeOplysningEmissionHCPlusNOX') ? undefined : json['miljoeOplysningEmissionHCPlusNOX'],
        'miljoeOplysningEmissionNOX': !exists(json, 'miljoeOplysningEmissionNOX') ? undefined : json['miljoeOplysningEmissionNOX'],
        'miljoeOplysningPartikelFilter': !exists(json, 'miljoeOplysningPartikelFilter') ? undefined : json['miljoeOplysningPartikelFilter'],
        'miljoeOplysningRoegtaethed': !exists(json, 'miljoeOplysningRoegtaethed') ? undefined : json['miljoeOplysningRoegtaethed'],
        'miljoeOplysningRoegtaethedOmdrejningstal': !exists(json, 'miljoeOplysningRoegtaethedOmdrejningstal') ? undefined : json['miljoeOplysningRoegtaethedOmdrejningstal'],
        'miljoeOplysningPartikler': !exists(json, 'miljoeOplysningPartikler') ? undefined : json['miljoeOplysningPartikler'],
        'motorKoerselStoej': !exists(json, 'motorKoerselStoej') ? undefined : json['motorKoerselStoej'],
        'motorStandStoej': !exists(json, 'motorStandStoej') ? undefined : json['motorStandStoej'],
        'motorStandStoejOmdrejningstal': !exists(json, 'motorStandStoejOmdrejningstal') ? undefined : json['motorStandStoejOmdrejningstal'],
        'normTypeNavn': !exists(json, 'normTypeNavn') ? undefined : json['normTypeNavn'],
        'adressePostNummer': !exists(json, 'adressePostNummer') ? undefined : json['adressePostNummer'],
        'adressePostNummerBy': !exists(json, 'adressePostNummerBy') ? undefined : json['adressePostNummerBy'],
        'koeretoejId': !exists(json, 'koeretoejId') ? undefined : json['koeretoejId'],
        'sekundaerStatus': !exists(json, 'sekundaerStatus') ? undefined : json['sekundaerStatus'],
        'sekundaerStatusDato': !exists(json, 'sekundaerStatusDato') ? undefined : (json['sekundaerStatusDato'] === null ? null : new Date(json['sekundaerStatusDato'])),
    };
}

export function BasicVehicleDataToJSON(value?: BasicVehicleData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'regNr': value.regNr,
        'stelNr': value.stelNr,
        'status': value.status,
        'statusDato': value.statusDato === undefined ? undefined : (value.statusDato === null ? null : value.statusDato.toISOString()),
        'foersteRegistreringDato': value.foersteRegistreringDato === undefined ? undefined : (value.foersteRegistreringDato === null ? null : value.foersteRegistreringDato.toISOString()),
        'leasingGyldigFra': value.leasingGyldigFra === undefined ? undefined : (value.leasingGyldigFra === null ? null : value.leasingGyldigFra.toISOString()),
        'leasingGyldigTil': value.leasingGyldigTil === undefined ? undefined : (value.leasingGyldigTil === null ? null : value.leasingGyldigTil.toISOString()),
        'koeretoejArtNavn': value.koeretoejArtNavn,
        'koeretoejAnvendelseNavn': value.koeretoejAnvendelseNavn,
        'totalVaegt': value.totalVaegt,
        'egenVaegt': value.egenVaegt,
        'vogntogVaegt': value.vogntogVaegt,
        'koereklarVaegtMinimum': value.koereklarVaegtMinimum,
        'tekniskTotalVaegt': value.tekniskTotalVaegt,
        'paahaengVognTotalVaegt': value.paahaengVognTotalVaegt,
        'akselAntal': value.akselAntal,
        'siddepladserMinimum': value.siddepladserMinimum,
        'tilkoblingsmulighed': value.tilkoblingsmulighed,
        'tilkoblingsvaegtUdenBremser': value.tilkoblingsvaegtUdenBremser,
        'tilkoblingsvaegtMedBremser': value.tilkoblingsvaegtMedBremser,
        'ncapTest': value.ncapTest,
        'koeretoejstand': value.koeretoejstand,
        'maerkeTypeNavn': value.maerkeTypeNavn,
        'modelTypeNavn': value.modelTypeNavn,
        'variantTypeNavn': value.variantTypeNavn,
        'farveTypeNavn': value.farveTypeNavn,
        'modelAar': value.modelAar,
        'karrosseriTypeNavn': value.karrosseriTypeNavn,
        'motorSlagVolumen': value.motorSlagVolumen,
        'motorStoersteEffekt': value.motorStoersteEffekt,
        'motorKmPerLiter': value.motorKmPerLiter,
        'motorKilometerstand': value.motorKilometerstand,
        'motorElektriskForbrug': value.motorElektriskForbrug,
        'drivkraftTypeNavn': value.drivkraftTypeNavn,
        'koeretoejUdstyrSamling': value.koeretoejUdstyrSamling,
        'motorMaerkning': value.motorMaerkning,
        'maksimumHastighed': value.maksimumHastighed,
        'motorCylinderAntal': value.motorCylinderAntal,
        'antalDoere': value.antalDoere,
        'faelgDaek': value.faelgDaek,
        'sporviddenForrest': value.sporviddenForrest,
        'sporviddenBagest': value.sporviddenBagest,
        'stelNummerAnbringelse': value.stelNummerAnbringelse,
        'passagerAntal': value.passagerAntal,
        'traekkendeAksler': value.traekkendeAksler,
        'akselAfstand': value.akselAfstand,
        'stoersteAkselTryk': value.stoersteAkselTryk,
        'miljoeOplysningCO2Udslip': value.miljoeOplysningCO2Udslip,
        'miljoeOplysningEmissionCO': value.miljoeOplysningEmissionCO,
        'miljoeOplysningEmissionHCPlusNOX': value.miljoeOplysningEmissionHCPlusNOX,
        'miljoeOplysningEmissionNOX': value.miljoeOplysningEmissionNOX,
        'miljoeOplysningPartikelFilter': value.miljoeOplysningPartikelFilter,
        'miljoeOplysningRoegtaethed': value.miljoeOplysningRoegtaethed,
        'miljoeOplysningRoegtaethedOmdrejningstal': value.miljoeOplysningRoegtaethedOmdrejningstal,
        'miljoeOplysningPartikler': value.miljoeOplysningPartikler,
        'motorKoerselStoej': value.motorKoerselStoej,
        'motorStandStoej': value.motorStandStoej,
        'motorStandStoejOmdrejningstal': value.motorStandStoejOmdrejningstal,
        'normTypeNavn': value.normTypeNavn,
        'adressePostNummer': value.adressePostNummer,
        'adressePostNummerBy': value.adressePostNummerBy,
        'koeretoejId': value.koeretoejId,
        'sekundaerStatus': value.sekundaerStatus,
        'sekundaerStatusDato': value.sekundaerStatusDato === undefined ? undefined : (value.sekundaerStatusDato === null ? null : value.sekundaerStatusDato.toISOString()),
    };
}

