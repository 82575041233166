/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsuranceData,
    InsuranceDataFromJSON,
    InsuranceDataFromJSONTyped,
    InsuranceDataToJSON,
} from './InsuranceData';
import {
    MotorregisterCarData,
    MotorregisterCarDataFromJSON,
    MotorregisterCarDataFromJSONTyped,
    MotorregisterCarDataToJSON,
} from './MotorregisterCarData';
import {
    MotorregisterInspectionData,
    MotorregisterInspectionDataFromJSON,
    MotorregisterInspectionDataFromJSONTyped,
    MotorregisterInspectionDataToJSON,
} from './MotorregisterInspectionData';
import {
    MotorregisterTekniskData,
    MotorregisterTekniskDataFromJSON,
    MotorregisterTekniskDataFromJSONTyped,
    MotorregisterTekniskDataToJSON,
} from './MotorregisterTekniskData';
import {
    MotorregisterWaiverData,
    MotorregisterWaiverDataFromJSON,
    MotorregisterWaiverDataFromJSONTyped,
    MotorregisterWaiverDataToJSON,
} from './MotorregisterWaiverData';
import {
    TaxData,
    TaxDataFromJSON,
    TaxDataFromJSONTyped,
    TaxDataToJSON,
} from './TaxData';

/**
 * 
 * @export
 * @interface FullVehicleData
 */
export interface FullVehicleData {
    /**
     * 
     * @type {TaxData}
     * @memberof FullVehicleData
     */
    afgift?: TaxData;
    /**
     * 
     * @type {InsuranceData}
     * @memberof FullVehicleData
     */
    forsikring?: InsuranceData;
    /**
     * 
     * @type {MotorregisterCarData}
     * @memberof FullVehicleData
     */
    generelt?: MotorregisterCarData;
    /**
     * 
     * @type {MotorregisterInspectionData}
     * @memberof FullVehicleData
     */
    syn?: MotorregisterInspectionData;
    /**
     * 
     * @type {MotorregisterTekniskData}
     * @memberof FullVehicleData
     */
    teknisk?: MotorregisterTekniskData;
    /**
     * 
     * @type {MotorregisterWaiverData}
     * @memberof FullVehicleData
     */
    tilladelser?: MotorregisterWaiverData;
}

export function FullVehicleDataFromJSON(json: any): FullVehicleData {
    return FullVehicleDataFromJSONTyped(json, false);
}

export function FullVehicleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullVehicleData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'afgift': !exists(json, 'afgift') ? undefined : TaxDataFromJSON(json['afgift']),
        'forsikring': !exists(json, 'forsikring') ? undefined : InsuranceDataFromJSON(json['forsikring']),
        'generelt': !exists(json, 'generelt') ? undefined : MotorregisterCarDataFromJSON(json['generelt']),
        'syn': !exists(json, 'syn') ? undefined : MotorregisterInspectionDataFromJSON(json['syn']),
        'teknisk': !exists(json, 'teknisk') ? undefined : MotorregisterTekniskDataFromJSON(json['teknisk']),
        'tilladelser': !exists(json, 'tilladelser') ? undefined : MotorregisterWaiverDataFromJSON(json['tilladelser']),
    };
}

export function FullVehicleDataToJSON(value?: FullVehicleData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'afgift': TaxDataToJSON(value.afgift),
        'forsikring': InsuranceDataToJSON(value.forsikring),
        'generelt': MotorregisterCarDataToJSON(value.generelt),
        'syn': MotorregisterInspectionDataToJSON(value.syn),
        'teknisk': MotorregisterTekniskDataToJSON(value.teknisk),
        'tilladelser': MotorregisterWaiverDataToJSON(value.tilladelser),
    };
}

