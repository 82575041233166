/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExtendedVehicleTechnicalData,
    ExtendedVehicleTechnicalDataFromJSON,
    ExtendedVehicleTechnicalDataFromJSONTyped,
    ExtendedVehicleTechnicalDataToJSON,
} from './ExtendedVehicleTechnicalData';
import {
    InsuranceData,
    InsuranceDataFromJSON,
    InsuranceDataFromJSONTyped,
    InsuranceDataToJSON,
} from './InsuranceData';
import {
    MotorregisterCarData,
    MotorregisterCarDataFromJSON,
    MotorregisterCarDataFromJSONTyped,
    MotorregisterCarDataToJSON,
} from './MotorregisterCarData';
import {
    TaxData,
    TaxDataFromJSON,
    TaxDataFromJSONTyped,
    TaxDataToJSON,
} from './TaxData';

/**
 * 
 * @export
 * @interface ExtendedVehicleData
 */
export interface ExtendedVehicleData {
    /**
     * 
     * @type {TaxData}
     * @memberof ExtendedVehicleData
     */
    afgift?: TaxData;
    /**
     * 
     * @type {InsuranceData}
     * @memberof ExtendedVehicleData
     */
    forsikring?: InsuranceData;
    /**
     * 
     * @type {Date}
     * @memberof ExtendedVehicleData
     */
    naesteSyn?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ExtendedVehicleData
     */
    sidsteSyn?: Date | null;
    /**
     * 
     * @type {MotorregisterCarData}
     * @memberof ExtendedVehicleData
     */
    udstyr?: MotorregisterCarData;
    /**
     * 
     * @type {MotorregisterCarData}
     * @memberof ExtendedVehicleData
     */
    generelt?: MotorregisterCarData;
    /**
     * 
     * @type {ExtendedVehicleTechnicalData}
     * @memberof ExtendedVehicleData
     */
    tekniskData?: ExtendedVehicleTechnicalData;
}

export function ExtendedVehicleDataFromJSON(json: any): ExtendedVehicleData {
    return ExtendedVehicleDataFromJSONTyped(json, false);
}

export function ExtendedVehicleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendedVehicleData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'afgift': !exists(json, 'afgift') ? undefined : TaxDataFromJSON(json['afgift']),
        'forsikring': !exists(json, 'forsikring') ? undefined : InsuranceDataFromJSON(json['forsikring']),
        'naesteSyn': !exists(json, 'naesteSyn') ? undefined : (json['naesteSyn'] === null ? null : new Date(json['naesteSyn'])),
        'sidsteSyn': !exists(json, 'sidsteSyn') ? undefined : (json['sidsteSyn'] === null ? null : new Date(json['sidsteSyn'])),
        'udstyr': !exists(json, 'udstyr') ? undefined : MotorregisterCarDataFromJSON(json['udstyr']),
        'generelt': !exists(json, 'generelt') ? undefined : MotorregisterCarDataFromJSON(json['generelt']),
        'tekniskData': !exists(json, 'tekniskData') ? undefined : ExtendedVehicleTechnicalDataFromJSON(json['tekniskData']),
    };
}

export function ExtendedVehicleDataToJSON(value?: ExtendedVehicleData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'afgift': TaxDataToJSON(value.afgift),
        'forsikring': InsuranceDataToJSON(value.forsikring),
        'naesteSyn': value.naesteSyn === undefined ? undefined : (value.naesteSyn === null ? null : value.naesteSyn.toISOString()),
        'sidsteSyn': value.sidsteSyn === undefined ? undefined : (value.sidsteSyn === null ? null : value.sidsteSyn.toISOString()),
        'udstyr': MotorregisterCarDataToJSON(value.udstyr),
        'generelt': MotorregisterCarDataToJSON(value.generelt),
        'tekniskData': ExtendedVehicleTechnicalDataToJSON(value.tekniskData),
    };
}

