/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InspectionError,
    InspectionErrorFromJSON,
    InspectionErrorFromJSONTyped,
    InspectionErrorToJSON,
} from './InspectionError';

/**
 * 
 * @export
 * @interface InspectionReport
 */
export interface InspectionReport {
    /**
     * 
     * @type {string}
     * @memberof InspectionReport
     */
    firma?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InspectionReport
     */
    cvr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InspectionReport
     */
    adresse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionReport
     */
    postNr?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InspectionReport
     */
    synstype?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InspectionReport
     */
    regNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InspectionReport
     */
    synsresultat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InspectionReport
     */
    synsdato?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InspectionReport
     */
    kmstandFormateret?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InspectionReport
     */
    slutTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InspectionReport
     */
    slutMinut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InspectionReport
     */
    kategori?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionReport
     */
    readonly kmstand?: number | null;
    /**
     * 
     * @type {Array<InspectionError>}
     * @memberof InspectionReport
     */
    fejl?: Array<InspectionError> | null;
}

export function InspectionReportFromJSON(json: any): InspectionReport {
    return InspectionReportFromJSONTyped(json, false);
}

export function InspectionReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firma': !exists(json, 'firma') ? undefined : json['firma'],
        'cvr': !exists(json, 'cvr') ? undefined : json['cvr'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'postNr': !exists(json, 'postNr') ? undefined : json['postNr'],
        'synstype': !exists(json, 'synstype') ? undefined : json['synstype'],
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
        'synsresultat': !exists(json, 'synsresultat') ? undefined : json['synsresultat'],
        'synsdato': !exists(json, 'synsdato') ? undefined : json['synsdato'],
        'kmstandFormateret': !exists(json, 'kmstandFormateret') ? undefined : json['kmstandFormateret'],
        'slutTime': !exists(json, 'slutTime') ? undefined : json['slutTime'],
        'slutMinut': !exists(json, 'slutMinut') ? undefined : json['slutMinut'],
        'kategori': !exists(json, 'kategori') ? undefined : json['kategori'],
        'kmstand': !exists(json, 'kmstand') ? undefined : json['kmstand'],
        'fejl': !exists(json, 'fejl') ? undefined : (json['fejl'] === null ? null : (json['fejl'] as Array<any>).map(InspectionErrorFromJSON)),
    };
}

export function InspectionReportToJSON(value?: InspectionReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firma': value.firma,
        'cvr': value.cvr,
        'adresse': value.adresse,
        'postNr': value.postNr,
        'synstype': value.synstype,
        'regNr': value.regNr,
        'synsresultat': value.synsresultat,
        'synsdato': value.synsdato,
        'kmstandFormateret': value.kmstandFormateret,
        'slutTime': value.slutTime,
        'slutMinut': value.slutMinut,
        'kategori': value.kategori,
        'fejl': value.fejl === undefined ? undefined : (value.fejl === null ? null : (value.fejl as Array<any>).map(InspectionErrorToJSON)),
    };
}

