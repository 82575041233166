/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Tax,
    TaxFromJSON,
    TaxFromJSONTyped,
    TaxToJSON,
} from './Tax';

/**
 * 
 * @export
 * @interface CarmatchData
 */
export interface CarmatchData {
    /**
     * 
     * @type {string}
     * @memberof CarmatchData
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarmatchData
     */
    model?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CarmatchData
     */
    leaset?: boolean;
    /**
     * 
     * @type {Array<Tax>}
     * @memberof CarmatchData
     */
    tax?: Array<Tax> | null;
    /**
     * 
     * @type {number}
     * @memberof CarmatchData
     */
    kmL?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CarmatchData
     */
    whKm?: number | null;
}

export function CarmatchDataFromJSON(json: any): CarmatchData {
    return CarmatchDataFromJSONTyped(json, false);
}

export function CarmatchDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarmatchData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'leaset': !exists(json, 'leaset') ? undefined : json['leaset'],
        'tax': !exists(json, 'tax') ? undefined : (json['tax'] === null ? null : (json['tax'] as Array<any>).map(TaxFromJSON)),
        'kmL': !exists(json, 'kmL') ? undefined : json['kmL'],
        'whKm': !exists(json, 'whKm') ? undefined : json['whKm'],
    };
}

export function CarmatchDataToJSON(value?: CarmatchData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'make': value.make,
        'model': value.model,
        'leaset': value.leaset,
        'tax': value.tax === undefined ? undefined : (value.tax === null ? null : (value.tax as Array<any>).map(TaxToJSON)),
        'kmL': value.kmL,
        'whKm': value.whKm,
    };
}

