/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ToldsynRequest
 */
export interface ToldsynRequest {
    /**
     * 
     * @type {Date}
     * @memberof ToldsynRequest
     */
    inspectionDateFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ToldsynRequest
     */
    inspectionDateTo?: Date | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ToldsynRequest
     */
    insuranceCompanies?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof ToldsynRequest
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ToldsynRequest
     */
    inspectionStationId?: number;
    /**
     * 
     * @type {string}
     * @memberof ToldsynRequest
     */
    leasingState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ToldsynRequest
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ToldsynRequest
     */
    model?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ToldsynRequest
     */
    amount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ToldsynRequest
     */
    includeVins?: boolean;
}

export function ToldsynRequestFromJSON(json: any): ToldsynRequest {
    return ToldsynRequestFromJSONTyped(json, false);
}

export function ToldsynRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ToldsynRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspectionDateFrom': !exists(json, 'inspectionDateFrom') ? undefined : (json['inspectionDateFrom'] === null ? null : new Date(json['inspectionDateFrom'])),
        'inspectionDateTo': !exists(json, 'inspectionDateTo') ? undefined : (json['inspectionDateTo'] === null ? null : new Date(json['inspectionDateTo'])),
        'insuranceCompanies': !exists(json, 'insuranceCompanies') ? undefined : json['insuranceCompanies'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'inspectionStationId': !exists(json, 'inspectionStationId') ? undefined : json['inspectionStationId'],
        'leasingState': !exists(json, 'leasingState') ? undefined : json['leasingState'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'includeVins': !exists(json, 'includeVins') ? undefined : json['includeVins'],
    };
}

export function ToldsynRequestToJSON(value?: ToldsynRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspectionDateFrom': value.inspectionDateFrom === undefined ? undefined : (value.inspectionDateFrom === null ? null : value.inspectionDateFrom.toISOString()),
        'inspectionDateTo': value.inspectionDateTo === undefined ? undefined : (value.inspectionDateTo === null ? null : value.inspectionDateTo.toISOString()),
        'insuranceCompanies': value.insuranceCompanies,
        'year': value.year,
        'inspectionStationId': value.inspectionStationId,
        'leasingState': value.leasingState,
        'make': value.make,
        'model': value.model,
        'amount': value.amount,
        'includeVins': value.includeVins,
    };
}

