/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Creditor,
    CreditorFromJSON,
    CreditorFromJSONTyped,
    CreditorToJSON,
} from './Creditor';

/**
 * 
 * @export
 * @interface LoanDocument
 */
export interface LoanDocument {
    /**
     * 
     * @type {string}
     * @memberof LoanDocument
     */
    hovedStol?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDocument
     */
    dato?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDocument
     */
    loebeNummer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDocument
     */
    dokumentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDocument
     */
    underpanthaver?: string | null;
    /**
     * 
     * @type {Array<Creditor>}
     * @memberof LoanDocument
     */
    kreditorer?: Array<Creditor> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoanDocument
     */
    tillaegsTekster?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof LoanDocument
     */
    interestRate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDocument
     */
    saerligeLaanevilkaar?: string | null;
}

export function LoanDocumentFromJSON(json: any): LoanDocument {
    return LoanDocumentFromJSONTyped(json, false);
}

export function LoanDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoanDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hovedStol': !exists(json, 'hovedStol') ? undefined : json['hovedStol'],
        'dato': !exists(json, 'dato') ? undefined : json['dato'],
        'loebeNummer': !exists(json, 'loebeNummer') ? undefined : json['loebeNummer'],
        'dokumentType': !exists(json, 'dokumentType') ? undefined : json['dokumentType'],
        'underpanthaver': !exists(json, 'underpanthaver') ? undefined : json['underpanthaver'],
        'kreditorer': !exists(json, 'kreditorer') ? undefined : (json['kreditorer'] === null ? null : (json['kreditorer'] as Array<any>).map(CreditorFromJSON)),
        'tillaegsTekster': !exists(json, 'tillaegsTekster') ? undefined : json['tillaegsTekster'],
        'interestRate': !exists(json, 'interestRate') ? undefined : json['interestRate'],
        'saerligeLaanevilkaar': !exists(json, 'saerligeLaanevilkaar') ? undefined : json['saerligeLaanevilkaar'],
    };
}

export function LoanDocumentToJSON(value?: LoanDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hovedStol': value.hovedStol,
        'dato': value.dato,
        'loebeNummer': value.loebeNummer,
        'dokumentType': value.dokumentType,
        'underpanthaver': value.underpanthaver,
        'kreditorer': value.kreditorer === undefined ? undefined : (value.kreditorer === null ? null : (value.kreditorer as Array<any>).map(CreditorToJSON)),
        'tillaegsTekster': value.tillaegsTekster,
        'interestRate': value.interestRate,
        'saerligeLaanevilkaar': value.saerligeLaanevilkaar,
    };
}

