/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TjekBilCarRiskLevel,
    TjekBilCarRiskLevelFromJSON,
    TjekBilCarRiskLevelFromJSONTyped,
    TjekBilCarRiskLevelToJSON,
} from './TjekBilCarRiskLevel';
import {
    TjekBilCarWantedHistory,
    TjekBilCarWantedHistoryFromJSON,
    TjekBilCarWantedHistoryFromJSONTyped,
    TjekBilCarWantedHistoryToJSON,
} from './TjekBilCarWantedHistory';
import {
    TjekBilUserCar,
    TjekBilUserCarFromJSON,
    TjekBilUserCarFromJSONTyped,
    TjekBilUserCarToJSON,
} from './TjekBilUserCar';

/**
 * 
 * @export
 * @interface TjekBilCar
 */
export interface TjekBilCar {
    /**
     * 
     * @type {number}
     * @memberof TjekBilCar
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TjekBilCar
     */
    licensePlate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilCar
     */
    chassisNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilCar
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilCar
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilCar
     */
    variant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilCar
     */
    vehicleType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TjekBilCar
     */
    year?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilCar
     */
    insuranceCompany?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TjekBilCar
     */
    loanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TjekBilCar
     */
    inspectionCount?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TjekBilCar
     */
    inspectionApproved?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof TjekBilCar
     */
    lastInspection?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof TjekBilCar
     */
    nextInspection?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof TjekBilCar
     */
    zipCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TjekBilCar
     */
    tax?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilCar
     */
    taxPeriod?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TjekBilCar
     */
    wanted?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof TjekBilCar
     */
    wantedDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof TjekBilCar
     */
    suspiciousMileage?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof TjekBilCar
     */
    suspiciousMileageDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof TjekBilCar
     */
    lastLookup?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof TjekBilCar
     */
    dmrId?: number;
    /**
     * 
     * @type {Array<TjekBilUserCar>}
     * @memberof TjekBilCar
     */
    userCars?: Array<TjekBilUserCar> | null;
    /**
     * 
     * @type {Array<TjekBilCarWantedHistory>}
     * @memberof TjekBilCar
     */
    wantedHistory?: Array<TjekBilCarWantedHistory> | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilCar
     */
    status?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TjekBilCar
     */
    insuranceCompanyDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof TjekBilCar
     */
    leasingPeriodEnd?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof TjekBilCar
     */
    stateAfterImport?: string | null;
    /**
     * 
     * @type {TjekBilCarRiskLevel}
     * @memberof TjekBilCar
     */
    riskLevel?: TjekBilCarRiskLevel;
    /**
     * 
     * @type {string}
     * @memberof TjekBilCar
     */
    blockedReasons?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TjekBilCar
     */
    blocked?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TjekBilCar
     */
    engineHorsepower?: number | null;
}

export function TjekBilCarFromJSON(json: any): TjekBilCar {
    return TjekBilCarFromJSONTyped(json, false);
}

export function TjekBilCarFromJSONTyped(json: any, ignoreDiscriminator: boolean): TjekBilCar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'licensePlate': !exists(json, 'licensePlate') ? undefined : json['licensePlate'],
        'chassisNumber': !exists(json, 'chassisNumber') ? undefined : json['chassisNumber'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'variant': !exists(json, 'variant') ? undefined : json['variant'],
        'vehicleType': !exists(json, 'vehicleType') ? undefined : json['vehicleType'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'insuranceCompany': !exists(json, 'insuranceCompany') ? undefined : json['insuranceCompany'],
        'loanAmount': !exists(json, 'loanAmount') ? undefined : json['loanAmount'],
        'inspectionCount': !exists(json, 'inspectionCount') ? undefined : json['inspectionCount'],
        'inspectionApproved': !exists(json, 'inspectionApproved') ? undefined : json['inspectionApproved'],
        'lastInspection': !exists(json, 'lastInspection') ? undefined : (json['lastInspection'] === null ? null : new Date(json['lastInspection'])),
        'nextInspection': !exists(json, 'nextInspection') ? undefined : (json['nextInspection'] === null ? null : new Date(json['nextInspection'])),
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
        'taxPeriod': !exists(json, 'taxPeriod') ? undefined : json['taxPeriod'],
        'wanted': !exists(json, 'wanted') ? undefined : json['wanted'],
        'wantedDate': !exists(json, 'wantedDate') ? undefined : (json['wantedDate'] === null ? null : new Date(json['wantedDate'])),
        'suspiciousMileage': !exists(json, 'suspiciousMileage') ? undefined : json['suspiciousMileage'],
        'suspiciousMileageDate': !exists(json, 'suspiciousMileageDate') ? undefined : (json['suspiciousMileageDate'] === null ? null : new Date(json['suspiciousMileageDate'])),
        'lastLookup': !exists(json, 'lastLookup') ? undefined : (json['lastLookup'] === null ? null : new Date(json['lastLookup'])),
        'dmrId': !exists(json, 'dmrId') ? undefined : json['dmrId'],
        'userCars': !exists(json, 'userCars') ? undefined : (json['userCars'] === null ? null : (json['userCars'] as Array<any>).map(TjekBilUserCarFromJSON)),
        'wantedHistory': !exists(json, 'wantedHistory') ? undefined : (json['wantedHistory'] === null ? null : (json['wantedHistory'] as Array<any>).map(TjekBilCarWantedHistoryFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'insuranceCompanyDate': !exists(json, 'insuranceCompanyDate') ? undefined : (json['insuranceCompanyDate'] === null ? null : new Date(json['insuranceCompanyDate'])),
        'leasingPeriodEnd': !exists(json, 'leasingPeriodEnd') ? undefined : (json['leasingPeriodEnd'] === null ? null : new Date(json['leasingPeriodEnd'])),
        'stateAfterImport': !exists(json, 'stateAfterImport') ? undefined : json['stateAfterImport'],
        'riskLevel': !exists(json, 'riskLevel') ? undefined : TjekBilCarRiskLevelFromJSON(json['riskLevel']),
        'blockedReasons': !exists(json, 'blockedReasons') ? undefined : json['blockedReasons'],
        'blocked': !exists(json, 'blocked') ? undefined : json['blocked'],
        'engineHorsepower': !exists(json, 'engineHorsepower') ? undefined : json['engineHorsepower'],
    };
}

export function TjekBilCarToJSON(value?: TjekBilCar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'licensePlate': value.licensePlate,
        'chassisNumber': value.chassisNumber,
        'make': value.make,
        'model': value.model,
        'variant': value.variant,
        'vehicleType': value.vehicleType,
        'year': value.year,
        'insuranceCompany': value.insuranceCompany,
        'loanAmount': value.loanAmount,
        'inspectionCount': value.inspectionCount,
        'inspectionApproved': value.inspectionApproved,
        'lastInspection': value.lastInspection === undefined ? undefined : (value.lastInspection === null ? null : value.lastInspection.toISOString()),
        'nextInspection': value.nextInspection === undefined ? undefined : (value.nextInspection === null ? null : value.nextInspection.toISOString()),
        'zipCode': value.zipCode,
        'tax': value.tax,
        'taxPeriod': value.taxPeriod,
        'wanted': value.wanted,
        'wantedDate': value.wantedDate === undefined ? undefined : (value.wantedDate === null ? null : value.wantedDate.toISOString()),
        'suspiciousMileage': value.suspiciousMileage,
        'suspiciousMileageDate': value.suspiciousMileageDate === undefined ? undefined : (value.suspiciousMileageDate === null ? null : value.suspiciousMileageDate.toISOString()),
        'lastLookup': value.lastLookup === undefined ? undefined : (value.lastLookup === null ? null : value.lastLookup.toISOString()),
        'dmrId': value.dmrId,
        'userCars': value.userCars === undefined ? undefined : (value.userCars === null ? null : (value.userCars as Array<any>).map(TjekBilUserCarToJSON)),
        'wantedHistory': value.wantedHistory === undefined ? undefined : (value.wantedHistory === null ? null : (value.wantedHistory as Array<any>).map(TjekBilCarWantedHistoryToJSON)),
        'status': value.status,
        'insuranceCompanyDate': value.insuranceCompanyDate === undefined ? undefined : (value.insuranceCompanyDate === null ? null : value.insuranceCompanyDate.toISOString()),
        'leasingPeriodEnd': value.leasingPeriodEnd === undefined ? undefined : (value.leasingPeriodEnd === null ? null : value.leasingPeriodEnd.toISOString()),
        'stateAfterImport': value.stateAfterImport,
        'riskLevel': TjekBilCarRiskLevelToJSON(value.riskLevel),
        'blockedReasons': value.blockedReasons,
        'blocked': value.blocked,
        'engineHorsepower': value.engineHorsepower,
    };
}

