/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MotorregisterInspectionData
 */
export interface MotorregisterInspectionData {
    /**
     * 
     * @type {Date}
     * @memberof MotorregisterInspectionData
     */
    naesteSyn?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MotorregisterInspectionData
     */
    sidsteSyn?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterInspectionData
     */
    sidsteSynResultat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterInspectionData
     */
    sidsteSynType?: string | null;
}

export function MotorregisterInspectionDataFromJSON(json: any): MotorregisterInspectionData {
    return MotorregisterInspectionDataFromJSONTyped(json, false);
}

export function MotorregisterInspectionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MotorregisterInspectionData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'naesteSyn': !exists(json, 'naesteSyn') ? undefined : (json['naesteSyn'] === null ? null : new Date(json['naesteSyn'])),
        'sidsteSyn': !exists(json, 'sidsteSyn') ? undefined : (json['sidsteSyn'] === null ? null : new Date(json['sidsteSyn'])),
        'sidsteSynResultat': !exists(json, 'sidsteSynResultat') ? undefined : json['sidsteSynResultat'],
        'sidsteSynType': !exists(json, 'sidsteSynType') ? undefined : json['sidsteSynType'],
    };
}

export function MotorregisterInspectionDataToJSON(value?: MotorregisterInspectionData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'naesteSyn': value.naesteSyn === undefined ? undefined : (value.naesteSyn === null ? null : value.naesteSyn.toISOString()),
        'sidsteSyn': value.sidsteSyn === undefined ? undefined : (value.sidsteSyn === null ? null : value.sidsteSyn.toISOString()),
        'sidsteSynResultat': value.sidsteSynResultat,
        'sidsteSynType': value.sidsteSynType,
    };
}

