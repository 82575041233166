/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtendedVehicleTechnicalData
 */
export interface ExtendedVehicleTechnicalData {
    /**
     * 
     * @type {number}
     * @memberof ExtendedVehicleTechnicalData
     */
    batteriKapacitet?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExtendedVehicleTechnicalData
     */
    elektriskRaekkevidde?: number | null;
}

export function ExtendedVehicleTechnicalDataFromJSON(json: any): ExtendedVehicleTechnicalData {
    return ExtendedVehicleTechnicalDataFromJSONTyped(json, false);
}

export function ExtendedVehicleTechnicalDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendedVehicleTechnicalData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'batteriKapacitet': !exists(json, 'batteriKapacitet') ? undefined : json['batteriKapacitet'],
        'elektriskRaekkevidde': !exists(json, 'elektriskRaekkevidde') ? undefined : json['elektriskRaekkevidde'],
    };
}

export function ExtendedVehicleTechnicalDataToJSON(value?: ExtendedVehicleTechnicalData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'batteriKapacitet': value.batteriKapacitet,
        'elektriskRaekkevidde': value.elektriskRaekkevidde,
    };
}

