/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PoliceWantedStatus,
    PoliceWantedStatusFromJSON,
    PoliceWantedStatusFromJSONTyped,
    PoliceWantedStatusToJSON,
} from './PoliceWantedStatus';
import {
    TjekBilCar,
    TjekBilCarFromJSON,
    TjekBilCarFromJSONTyped,
    TjekBilCarToJSON,
} from './TjekBilCar';

/**
 * 
 * @export
 * @interface TjekBilCarWantedHistory
 */
export interface TjekBilCarWantedHistory {
    /**
     * 
     * @type {number}
     * @memberof TjekBilCarWantedHistory
     */
    id?: number;
    /**
     * 
     * @type {PoliceWantedStatus}
     * @memberof TjekBilCarWantedHistory
     */
    wantedStatus?: PoliceWantedStatus;
    /**
     * 
     * @type {Date}
     * @memberof TjekBilCarWantedHistory
     */
    wantedStatusDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof TjekBilCarWantedHistory
     */
    carId?: number;
    /**
     * 
     * @type {TjekBilCar}
     * @memberof TjekBilCarWantedHistory
     */
    car?: TjekBilCar;
}

export function TjekBilCarWantedHistoryFromJSON(json: any): TjekBilCarWantedHistory {
    return TjekBilCarWantedHistoryFromJSONTyped(json, false);
}

export function TjekBilCarWantedHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TjekBilCarWantedHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'wantedStatus': !exists(json, 'wantedStatus') ? undefined : PoliceWantedStatusFromJSON(json['wantedStatus']),
        'wantedStatusDate': !exists(json, 'wantedStatusDate') ? undefined : (new Date(json['wantedStatusDate'])),
        'carId': !exists(json, 'carId') ? undefined : json['carId'],
        'car': !exists(json, 'car') ? undefined : TjekBilCarFromJSON(json['car']),
    };
}

export function TjekBilCarWantedHistoryToJSON(value?: TjekBilCarWantedHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'wantedStatus': PoliceWantedStatusToJSON(value.wantedStatus),
        'wantedStatusDate': value.wantedStatusDate === undefined ? undefined : (value.wantedStatusDate.toISOString()),
        'carId': value.carId,
        'car': TjekBilCarToJSON(value.car),
    };
}

