/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MotorregisterCarData
 */
export interface MotorregisterCarData {
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    typeGodkendelsesNummer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    stelNummer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    maerke?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    variant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    koeretoejArt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    regNr?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MotorregisterCarData
     */
    firstRegDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    koeretoejAnvendelse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    status?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MotorregisterCarData
     */
    statusDato?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterCarData
     */
    kid?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    farve?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    standEfterImport?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    modelAar?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterCarData
     */
    ncapTest?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    type?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterCarData
     */
    automatgear?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterCarData
     */
    hoejrestyring?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterCarData
     */
    turbo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterCarData
     */
    laederSkindSaede?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterCarData
     */
    massageSaede?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterCarData
     */
    memorySaede?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterCarData
     */
    sportKomfortSaede?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterCarData
     */
    ventilationISaede?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterCarData
     */
    antalAirbags?: number;
    /**
     * 
     * @type {number}
     * @memberof MotorregisterCarData
     */
    koeretoejMotorKilometerstand?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    sekundaerStatus?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MotorregisterCarData
     */
    sekundaerStatusDato?: Date | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MotorregisterCarData
     */
    koeretoejUdstyrSamling?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MotorregisterCarData
     */
    blockedStatus?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    blockedDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterCarData
     */
    readonly kmDisplay?: string | null;
}

export function MotorregisterCarDataFromJSON(json: any): MotorregisterCarData {
    return MotorregisterCarDataFromJSONTyped(json, false);
}

export function MotorregisterCarDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MotorregisterCarData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeGodkendelsesNummer': !exists(json, 'typeGodkendelsesNummer') ? undefined : json['typeGodkendelsesNummer'],
        'stelNummer': !exists(json, 'stelNummer') ? undefined : json['stelNummer'],
        'maerke': !exists(json, 'maerke') ? undefined : json['maerke'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'variant': !exists(json, 'variant') ? undefined : json['variant'],
        'koeretoejArt': !exists(json, 'koeretoejArt') ? undefined : json['koeretoejArt'],
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
        'firstRegDate': !exists(json, 'firstRegDate') ? undefined : (json['firstRegDate'] === null ? null : new Date(json['firstRegDate'])),
        'koeretoejAnvendelse': !exists(json, 'koeretoejAnvendelse') ? undefined : json['koeretoejAnvendelse'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusDato': !exists(json, 'statusDato') ? undefined : (json['statusDato'] === null ? null : new Date(json['statusDato'])),
        'kid': !exists(json, 'kid') ? undefined : json['kid'],
        'farve': !exists(json, 'farve') ? undefined : json['farve'],
        'standEfterImport': !exists(json, 'standEfterImport') ? undefined : json['standEfterImport'],
        'modelAar': !exists(json, 'modelAar') ? undefined : json['modelAar'],
        'ncapTest': !exists(json, 'ncapTest') ? undefined : json['ncapTest'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'automatgear': !exists(json, 'automatgear') ? undefined : json['automatgear'],
        'hoejrestyring': !exists(json, 'hoejrestyring') ? undefined : json['hoejrestyring'],
        'turbo': !exists(json, 'turbo') ? undefined : json['turbo'],
        'laederSkindSaede': !exists(json, 'laederSkindSaede') ? undefined : json['laederSkindSaede'],
        'massageSaede': !exists(json, 'massageSaede') ? undefined : json['massageSaede'],
        'memorySaede': !exists(json, 'memorySaede') ? undefined : json['memorySaede'],
        'sportKomfortSaede': !exists(json, 'sportKomfortSaede') ? undefined : json['sportKomfortSaede'],
        'ventilationISaede': !exists(json, 'ventilationISaede') ? undefined : json['ventilationISaede'],
        'antalAirbags': !exists(json, 'antalAirbags') ? undefined : json['antalAirbags'],
        'koeretoejMotorKilometerstand': !exists(json, 'koeretoejMotorKilometerstand') ? undefined : json['koeretoejMotorKilometerstand'],
        'sekundaerStatus': !exists(json, 'sekundaerStatus') ? undefined : json['sekundaerStatus'],
        'sekundaerStatusDato': !exists(json, 'sekundaerStatusDato') ? undefined : (json['sekundaerStatusDato'] === null ? null : new Date(json['sekundaerStatusDato'])),
        'koeretoejUdstyrSamling': !exists(json, 'koeretoejUdstyrSamling') ? undefined : json['koeretoejUdstyrSamling'],
        'blockedStatus': !exists(json, 'blockedStatus') ? undefined : json['blockedStatus'],
        'blockedDescription': !exists(json, 'blockedDescription') ? undefined : json['blockedDescription'],
        'kmDisplay': !exists(json, 'kmDisplay') ? undefined : json['kmDisplay'],
    };
}

export function MotorregisterCarDataToJSON(value?: MotorregisterCarData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'typeGodkendelsesNummer': value.typeGodkendelsesNummer,
        'stelNummer': value.stelNummer,
        'maerke': value.maerke,
        'model': value.model,
        'variant': value.variant,
        'koeretoejArt': value.koeretoejArt,
        'regNr': value.regNr,
        'firstRegDate': value.firstRegDate === undefined ? undefined : (value.firstRegDate === null ? null : value.firstRegDate.toISOString()),
        'koeretoejAnvendelse': value.koeretoejAnvendelse,
        'status': value.status,
        'statusDato': value.statusDato === undefined ? undefined : (value.statusDato === null ? null : value.statusDato.toISOString()),
        'kid': value.kid,
        'farve': value.farve,
        'standEfterImport': value.standEfterImport,
        'modelAar': value.modelAar,
        'ncapTest': value.ncapTest,
        'type': value.type,
        'automatgear': value.automatgear,
        'hoejrestyring': value.hoejrestyring,
        'turbo': value.turbo,
        'laederSkindSaede': value.laederSkindSaede,
        'massageSaede': value.massageSaede,
        'memorySaede': value.memorySaede,
        'sportKomfortSaede': value.sportKomfortSaede,
        'ventilationISaede': value.ventilationISaede,
        'antalAirbags': value.antalAirbags,
        'koeretoejMotorKilometerstand': value.koeretoejMotorKilometerstand,
        'sekundaerStatus': value.sekundaerStatus,
        'sekundaerStatusDato': value.sekundaerStatusDato === undefined ? undefined : (value.sekundaerStatusDato === null ? null : value.sekundaerStatusDato.toISOString()),
        'koeretoejUdstyrSamling': value.koeretoejUdstyrSamling,
        'blockedStatus': value.blockedStatus,
        'blockedDescription': value.blockedDescription,
    };
}

