/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HesselLead,
    HesselLeadFromJSON,
    HesselLeadFromJSONTyped,
    HesselLeadToJSON,
} from './HesselLead';

/**
 * 
 * @export
 * @interface HesselIntegrationResponse
 */
export interface HesselIntegrationResponse {
    /**
     * 
     * @type {Array<HesselLead>}
     * @memberof HesselIntegrationResponse
     */
    hesselPermissions?: Array<HesselLead> | null;
}

export function HesselIntegrationResponseFromJSON(json: any): HesselIntegrationResponse {
    return HesselIntegrationResponseFromJSONTyped(json, false);
}

export function HesselIntegrationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HesselIntegrationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hesselPermissions': !exists(json, 'hesselPermissions') ? undefined : (json['hesselPermissions'] === null ? null : (json['hesselPermissions'] as Array<any>).map(HesselLeadFromJSON)),
    };
}

export function HesselIntegrationResponseToJSON(value?: HesselIntegrationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hesselPermissions': value.hesselPermissions === undefined ? undefined : (value.hesselPermissions === null ? null : (value.hesselPermissions as Array<any>).map(HesselLeadToJSON)),
    };
}

