/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TjekBilCar,
    TjekBilCarFromJSON,
    TjekBilCarFromJSONTyped,
    TjekBilCarToJSON,
} from './TjekBilCar';
import {
    TjekBilUser,
    TjekBilUserFromJSON,
    TjekBilUserFromJSONTyped,
    TjekBilUserToJSON,
} from './TjekBilUser';

/**
 * 
 * @export
 * @interface TjekBilUserCar
 */
export interface TjekBilUserCar {
    /**
     * 
     * @type {boolean}
     * @memberof TjekBilUserCar
     */
    isMine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TjekBilUserCar
     */
    legacyIsMine?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TjekBilUserCar
     */
    carId?: number;
    /**
     * 
     * @type {TjekBilCar}
     * @memberof TjekBilUserCar
     */
    car?: TjekBilCar;
    /**
     * 
     * @type {number}
     * @memberof TjekBilUserCar
     */
    userId?: number;
    /**
     * 
     * @type {TjekBilUser}
     * @memberof TjekBilUserCar
     */
    user?: TjekBilUser;
}

export function TjekBilUserCarFromJSON(json: any): TjekBilUserCar {
    return TjekBilUserCarFromJSONTyped(json, false);
}

export function TjekBilUserCarFromJSONTyped(json: any, ignoreDiscriminator: boolean): TjekBilUserCar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isMine': !exists(json, 'isMine') ? undefined : json['isMine'],
        'legacyIsMine': !exists(json, 'legacyIsMine') ? undefined : json['legacyIsMine'],
        'carId': !exists(json, 'carId') ? undefined : json['carId'],
        'car': !exists(json, 'car') ? undefined : TjekBilCarFromJSON(json['car']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : TjekBilUserFromJSON(json['user']),
    };
}

export function TjekBilUserCarToJSON(value?: TjekBilUserCar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isMine': value.isMine,
        'legacyIsMine': value.legacyIsMine,
        'carId': value.carId,
        'car': TjekBilCarToJSON(value.car),
        'userId': value.userId,
        'user': TjekBilUserToJSON(value.user),
    };
}

