/* tslint:disable */
/* eslint-disable */
/**
 * Tjekbil API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MotorregisterAssessment,
    MotorregisterAssessmentFromJSON,
    MotorregisterAssessmentFromJSONTyped,
    MotorregisterAssessmentToJSON,
} from './MotorregisterAssessment';

/**
 * 
 * @export
 * @interface MotorregisterAssessmentData
 */
export interface MotorregisterAssessmentData {
    /**
     * 
     * @type {string}
     * @memberof MotorregisterAssessmentData
     */
    carId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterAssessmentData
     */
    vin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MotorregisterAssessmentData
     */
    regNr?: string | null;
    /**
     * 
     * @type {Array<MotorregisterAssessment>}
     * @memberof MotorregisterAssessmentData
     */
    assessments?: Array<MotorregisterAssessment> | null;
}

export function MotorregisterAssessmentDataFromJSON(json: any): MotorregisterAssessmentData {
    return MotorregisterAssessmentDataFromJSONTyped(json, false);
}

export function MotorregisterAssessmentDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MotorregisterAssessmentData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'carId': !exists(json, 'carId') ? undefined : json['carId'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
        'assessments': !exists(json, 'assessments') ? undefined : (json['assessments'] === null ? null : (json['assessments'] as Array<any>).map(MotorregisterAssessmentFromJSON)),
    };
}

export function MotorregisterAssessmentDataToJSON(value?: MotorregisterAssessmentData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'carId': value.carId,
        'vin': value.vin,
        'regNr': value.regNr,
        'assessments': value.assessments === undefined ? undefined : (value.assessments === null ? null : (value.assessments as Array<any>).map(MotorregisterAssessmentToJSON)),
    };
}

