/* tslint:disable */
/* eslint-disable */
export * from './BankruptcyData';
export * from './BasicVehicleData';
export * from './CarmatchData';
export * from './Creditor';
export * from './DebtData';
export * from './DocCount';
export * from './ExtendedVehicleData';
export * from './ExtendedVehicleTechnicalData';
export * from './FullVehicleData';
export * from './GlobalVehicleData';
export * from './HesselIntegrationResponse';
export * from './HesselLead';
export * from './InitSearchFields';
export * from './InitialFilterData';
export * from './InitialGraphData';
export * from './InspectionError';
export * from './InspectionReport';
export * from './InspectionStationName';
export * from './InsuranceData';
export * from './LoanDocument';
export * from './MotorregisterAssessment';
export * from './MotorregisterAssessmentData';
export * from './MotorregisterAssessmentOffset';
export * from './MotorregisterCarData';
export * from './MotorregisterInspectionData';
export * from './MotorregisterTekniskData';
export * from './MotorregisterWaiverData';
export * from './PartikelFilterData';
export * from './PoliceWantedStatus';
export * from './PolitiData';
export * from './PopulationFields';
export * from './ProblemDetails';
export * from './SearchForm';
export * from './SearchResult';
export * from './Tax';
export * from './TaxData';
export * from './TaxDetail';
export * from './TaxTransaction';
export * from './TekniskData';
export * from './TjekBilCar';
export * from './TjekBilCarRiskLevel';
export * from './TjekBilCarWantedHistory';
export * from './TjekBilInspection';
export * from './TjekBilInspectionError';
export * from './TjekBilInspectionStation';
export * from './TjekBilUser';
export * from './TjekBilUserCar';
export * from './TjekBilYearsWithoutIncident';
export * from './ToldsynByCvrResult';
export * from './ToldsynByLeasingYearEndResult';
export * from './ToldsynByMakeResult';
export * from './ToldsynByModelYearResult';
export * from './ToldsynByStateResult';
export * from './ToldsynByYearResult';
export * from './ToldsynRequest';
export * from './VehicleDocumentWithToldsyn';
export * from './VinValidityScore';
